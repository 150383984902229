<script>
import { currentLoginVipStatus } from "@/api/newVersion/vip";
import basicMixin from "./mixins/basic";
import Bus from "@/utils/bus.js";
export default {
  inject: ["control"],
  name: "MessageText",
  mixins: [basicMixin],
  data() {
    return {
      replaceInit: false,
      translateEdit: false,
      curDom: null,
      keyword: "",
      user: {},
    };
  },
  props: {
    downLoadProgress: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    decryptMessageProgress: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  created() {
    this.user = this.$store.getters.userInfo;
    this.setContextMenu();
  },
  mounted() {
    // 强制刷新一下
    this.$forceUpdate();
    this.$nextTick(() => {
      if (this.message.lightMsg && this.$el.offsetTop - 50) {
        setTimeout(() => {
          this.jumpMessage();
        }, 500);
      }
    });
  },
  methods: {
    // 跳到搜索位置
    jumpMessage() {
      if (!this.message) {
        return;
      }
      this.control.$refs["messageView"].$refs[
        `${this.message.sendTime + this.messageIndex}`
      ][0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    // 装填右键菜单操作选项
    setContextMenu() {
      if (
        typeof this.message.text !== "string" ||
        this.message.subType === "link"
      ) {
        // this.contextmenu = [];
      }
    },
    async cancel() {
      // 这里先判断自己是不是会员，如果不是会员，则跳转到开通会员页
      const loginVipStatus = await currentLoginVipStatus();
      if (!loginVipStatus.data.data.vipStatus) {
        this.$confirm(this.$t("BecomeemberOpenEncryptedMessages"), "提示", {
          confirmButtonText: " ",
          type: "warning",
          customClass: "melinked-tips",
          showCancelButton: false,
          confirmButtonClass: "el-icon-right my-confirm-btn",
        })
          .then(() => {
            this.$store.commit("setVipModeStatus", true);
          })
          .catch(() => {});
      } else {
        const currentDecryptMessages = this.$store.state.vip
          .currentDecryptMessages;
        currentDecryptMessages[this.message.id] = this.message;
        this.$store.commit("setDecryptModeStatus", {
          visible: true,
          messageId: this.message.id,
        });
      }
    },
    cancelDown() {
      Bus.$emit("cancelDown");
    },
  },

  render() {
    const { translate, msgs, id } = this.message;
    let isShwo = false;
    if (this.$store.state.vip.currentDecryptMessages[id]) {
      isShwo = true;
    }
    return this.elPackage([
      this.elContent([
        [
          <p
            ref="text"
            class="encryp-block"
            // onClick={(event) => {
            //   event.stopPropagation();
            //   this.cancel();
            // }}
          >
            <div class="lock-block">
              <i class="iconfont_Me  icon-a-lujing13297"></i>
            </div>

            {this.downLoadProgress < 100 &&
              this.downLoadProgress > 0 &&
              isShwo && (
                <div
                  class="cancelDown"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.cancelDown();
                  }}
                >
                  <i class="el-icon-error"></i>
                </div>
              )}
            {this.downLoadProgress < 100 &&
              this.downLoadProgress > 0 &&
              isShwo && (
                <el-progress
                  type="circle"
                  class="imelink-message-enc-file__progress"
                  percentage={this.downLoadProgress}
                  color="#33cc66"
                  stroke-width={2}
                  text-inside
                  width={29}
                  show-text={false}
                />
              )}
            {this.decryptMessageProgress < 100 &&
              this.decryptMessageProgress > 0 &&
              isShwo && (
                <el-progress
                  type="circle"
                  class="imelink-message-decrypt__progress"
                  percentage={this.decryptMessageProgress}
                  color="#33cc66"
                  stroke-width={2}
                  text-inside
                  width={29}
                  show-text={false}
                />
              )}
          </p>,
        ],
      ]),
    ]);
  },
};
</script>

<style lang="stylus">
@import '~styles/utils/index';

.errorIcon {
font-size: 20px;
margin-left: 0.5rem;

&.el-icon-warning {
  color: red;
}

&.el-icon-loading {
  color: #999999;
}
}

.imelink-message-text {
padding: 0 !important;
display: flex;
flex-flow: column;

&.imelink-message__content {
  font-size: 12px;
}

+e(error) {
  cursor: pointer;
}

+e(editBox) {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  +m(textarea) {
    width: 100%;
    display: inline-block;
    line-height: 22px;
    min-height: 100px;
    // max-height: 200px;
    border: 0;
    padding: 0.25rem;
    // overflow-x: hidden;
    transition: all 200ms linear;
    // height -webkit-fill-available
    overflow-y: scroll;
    scrollbar-light();
  }
}

+e(oldTranslate) {
  background: #c6edcc;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

+e(translate) {
  &.trans {
    &::after {
      font-family: 'icons' !important;
      content: '\e694';
      // position: absolute;
      // margin-left: 5px;
      margin-top: 5px;
      // padding-left 5px;
      // right: -1px;
      // top: 5px;
      // border: 1px solid #e5e5e5;
      text-align: center;
      border-radius: 20px;
      background: #ffffff;
      line-height: 15px;
      color: #999999;
      font-size: 16px;
      cursor: pointer;
    }
  }

  &.all {
    &::after {
      font-family: 'icons' !important;
      content: '\e80a' !important;
    }
  }

  &.trans_list {
    display: flex;
    align-items: flex-start;
    background: none;
    border-top: 1px solid #ffffff;
    width: 100%;

    .imelink-message-text__translateMsg {
      flex: 1;
      // padding: 0.5rem 12px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      // display: flex;
      flex-flow: row;
      cursor: default;
      max-height: 24px;

      &.showAll {
        white-space: normal;
        height: auto;
        display: flex;
        word-break: break-word;
        display: inline;
        max-height: none !important;

        img {
          float: left;
        }

        &.hasMore {
        }
      }

      &.edit {
        background: #ffffff;
      }

      +e(btns) {
        font-size: 15px;
        display: flex;
        flex-flow: row;
        margin-top: 0.5rem;

        i {
          padding: 0.1rem 0.6rem;
          background: #ffffff;
          margin: 0 0.5rem 0 0;
          border-radius: 3px;
          cursor: pointer;

          &.el-icon-check {
            background: #33cc66;
            color: #ffffff;
          }

          &.el-icon-close {
            background: #9da2a6;
            color: #ffffff;
          }
        }
      }
    }

    // border-radius: 5px;
    &:last-child {
      // border 0
    }

    .el-icon-loading {
      margin-right: 0.3rem;
      font-size: 18px;
    }
  }
}

+m(editBtns) {
  position: absolute;
  right: 0;
  bottom: 15px;

  .icon {
    margin: 0 0.5rem 0;
    padding: 0 0.5rem;
    border-radius: 3px;
    color: #ffffff;
    font-size: 18px;

    &.el-icon-close {
      background: #999999;
    }

    &.el-icon-check {
      background: #33cc66;
    }
  }
}
}

+b(imelink-message-text) {
display: inline-block;
word-wrap: break-word;
word-break: break-word;
cursor: text !important;
line-height: 22px !important;

img {
  vertical-align: middle;
  // height: 22px;
  position: relative;
  top: -2px;
}

+e(translateMsgs) {
  flex: 1;
  // padding: 0.5rem 12px;
  width: 100%;
  overflow: hidden;
  // text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  display: flex;
  flex-flow: row;
  cursor: default;
  padding: 0 5px;

  &.showAll {
    white-space: normal;
    height: auto;
    display: flex;
    word-break: break-all;
    display: inline;

    &::after {
      top: auto;
      bottom: 3px;
      font-family: 'icons' !important;
      content: '\e80a';
    }
  }

  &.edit {
    background: #ffffff;
  }

  &::after {
    font-family: 'icons' !important;
    content: '\e694';
    position: absolute;
    margin-left: 0.9rem;
    right: 1px;
    top: 14px;
    // border: 1px solid #e5e5e5;
    text-align: center;
    border-radius: 20px;
    background: #ffffff;
    line-height: 15px;
    color: #999999;
    font-size: 20px;
  }

  +e(btns) {
    font-size: 15px;
    display: flex;
    flex-flow: row;
    margin-top: 0.5rem;

    i {
      padding: 0.1rem 0.6rem;
      background: #ffffff;
      margin: 0 0.5rem 0 0;
      border-radius: 3px;
      cursor: pointer;

      &.el-icon-check {
        background: #33cc66;
        color: #ffffff;
      }

      &.el-icon-close {
        background: #9da2a6;
        color: #ffffff;
      }
    }
  }
}
}

.imelink-message-text {
.imelink-message-text__translate {
  padding: 8px 12px;
  box-sizing: border-box;
  outline: none;
}

hr {
  border: 0;
  height: 1px;
  background: #90cf99;
  margin: 10px 0;

  &:last-child {
    display: none;
  }
}

.imelink-message-text__translate-edit {
  left: auto;
  right: -30px;
  top: 8px;
  position: absolute;
  color: #52bd68;
  font-size: 16px;
  cursor: pointer;
  padding: 4px;
  z-index: 1;
}
}


.imelink-message-text__translate.imelink-message-text__translate--edit {
border: 1px solid #4ece6c;
background: #fff;
min-width: 120px;
}

.imelink-message-text__translate-action {
position: absolute;
bottom: -34px;
right: 0;
z-index: 1;
}

.imelink-message--self {
.imelink-message-text__translate {
  border-top-color: #fff;
  background: #e6f5e9; // #c6edcc;
}

.imelink-message-text__translate-edit {
  right: auto;
  left: -30px;
  top: 8px;
}

.imelink-message-translate__avatar {
  right: auto;
  left: -30px;
}
}

.imelink-message-translate__avatar {
position: absolute;
right: -30px;

// bottom: 3px;
.me-avatar__error {
  font-size: 12px;
}
}

// 加密部分
.encryp-block{
  position relative
  display block
  padding: 6px 8px;
  .lock-block{
      position: absolute
      width: 26px;
      height: 26px;
      padding: 10px;
      background: #f1f1f1;
      border: 2px solid #ffffff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      // .lock-inner{
      //   background: red;
      //   width: 36px;
      //   height: 36px;
      //   display: flex;
      //   border-radius: 50%;
      //   justify-content: center;
      //   align-items: center;
      //   background: #f1f1f1;
      // }

  }
}

// 下载文件消息
.imelink-message-enc-file__progress {
      position:absolute;
      z-index 10000;
      .el-progress-circle {
        background: #f1f1f1 !important;
        position: relative;
      }

      .el-progress-circle::after {
        font-family: element-icons !important;
        content: '\e6eb';
        position: absolute;
        left: 50%;
        top: 50%;
        color: #636363;
        font-size: 14px;
        font-weight: bold;
        transform: translate(-50%, -50%);
      }

      .el-progress-circle__track {
       stroke: #fff;
      }
    }
// 解密消息进度
.imelink-message-decrypt__progress{
    position:absolute;
      z-index 10000;
      .el-progress-circle {
        background: #f1f1f1 !important;
        position: relative;
      }
      .el-progress-circle::after {
        font-family: iconfont_Me !important;
        content: '\E6FC';
        position: absolute;
        left: 50%;
        top: 50%;
        color: #636363;
        font-size: 14px;
        font-weight: bold;
        transform: translate(-50%, -50%);
      }

      .el-progress-circle__track {
       stroke: #fff;
      }
}

// 取消下载
.cancelDown{
  position:absolute;
  z-index 10000;
  right: -20px
  top:-16px
  i{
    color:red
    font-size: 18px
  }
}

.imelink-message--self{
  .cancelDown{
  position:absolute;
  z-index 10000;
  right: 20px
  top:-16px
  i{
    color:red
    font-size: 18px
  }
}
}


.imelink-message--self {
  .lock-block{
    left:-28px
    background: #c6edcc
  }
  .imelink-message-decrypt__progress{
    left:-28px
  }
  .imelink-message-enc-file__progress {
    left:-28px
  }
}
</style>
