<script>
import basicMixin from "./mixins/basic";
import Bus from "@/utils/bus.js";
import { toBytesSize, downloadFile, env } from "@/utils";
export default {
  name: "MessageFile",
  inject: ["control"],
  mixins: [basicMixin],
  data() {
    return {
      downFlag: false,
    };
  },
  created() {
    this.bindClick = true;
    // 向右键菜单放入下载按钮

    // 如果是解密后的消息
    if (this.message.isDecrypted && !this.message.isSelfMeassge) {
      this.contextmenu = [];
    } else {
      this.contextmenu.push({
        icon: "el-icon-download",
        click: () => this.handleContentClick(),
      });
    }
  },
  mounted() {
    this.handleMessage();
    // 强制刷新一下
    this.$forceUpdate();
    this.$nextTick(() => {
      if (this.message.lightMsg && this.$el.offsetTop - 50) {
        setTimeout(() => {
          this.jumpMessage();
        }, 500);
      }
    });
  },
  methods: {
    // 搜索消息定位到聊天窗口高亮
    handleMessage() {
      let keyword = localStorage.getItem("catchWord");
      this.keyword = keyword;
      if (this.message.lightMsg && keyword) {
        this.message.fileName = this.message.fileName.replace(
          keyword,
          '<font color="red">' + keyword + "</font>"
        );
      }
    },
    // 跳到搜索位置
    jumpMessage() {
      if (!this.message) {
        return;
      }
      this.control.$refs["messageView"].$refs[
        `${this.message.sendTime + this.messageIndex}`
      ][0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    // 下载当前文件
    handleContentClick() {
      // 是否是加密后的视频消息，如果是则开始倒计时
      if (this.message.isDecrypted && !this.message.isSelfMeassge) {
        Bus.$emit("messageFileStartTiming", this.message.id);
      }
      let url = null;
      let isBblob =
        (this.message.onlineurl || this.message.url).indexOf("blob") != "-1";
      if (isBblob) {
        url = this.message.onlineurl || this.message.url;
      } else {
        url = `${env(
          "BASEURL"
        )}/rest/user/download?downloadUrl=${this.formartImageUrl(
          this.message.onlineurl || this.message.url
        )}&fileName=${encodeURIComponent(
          this.message.fileName
            .replace('<font color="red">', "")
            .replace("</font>", "")
        )}`;
      }
      downloadFile(
        url,
        this.message.fileName
          .replace('<font color="red">', "")
          .replace("</font>", "")
      );
    },
    // 自定义下载
    customDownload(url, title) {
      url = this.message.onlineurl;
      title = this.message.fileName;
      const self = this;
      self.loading = true;
      const x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function() {
        const herf = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = herf;
        a.download = title;
        a.click();
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(x.response, title);
        }
        a.remove();
      };
      // todo: 添加下载进度
      x.onprogress = function(event) {
        // 只有 e.lengthComputable 为真，才会有进度条的信息
        if (event.lengthComputable) {
          var percentComplete = event.loaded / event.total;
          var progress = parseInt(percentComplete * 100);
          self.shipvalue = progress++;
        }
      };
      x.onreadystatechange = function() {
        if (x.readyState === 4) {
          // 4 = "loaded"
          if (x.status === 200) {
            // 200 = "OK"
            setTimeout(() => {
              self.loading = false;
            }, 800);
          } else {
            alert("Problem retrieving data:" + x.statusText);
          }
        }
      };
      x.send();
    },
    //取消上传
    cancel() {
      this.subscription.unsubscribe();
      this.$el.parentNode.remove();
    },
  },
  computed: {
    nameSfx() {
      const name = this.message.fileName;
      if (!name) return "";
      const arr = name.split(".");
      let str = arr[arr.length - 1].toUpperCase();
      if (str.length > 3) {
        str = str.substring(0, 3);
      }
      return str;
    },
  },
  render() {
    const {
      message,
      control: { user },
    } = this;
    return this.elPackage([
      this.elContent([
        <div class="file--box">
          {message.fileUploadProgress < 100 && message.fileUploadProgress > 0 && (
            <div
              class="cancel"
              onClick={(event) => {
                event.stopPropagation();
                this.cancel();
              }}
            >
              <i class="iconfont_Me icon-delete_fill"></i>
            </div>
          )}
          <div class="imelink-message-file__icon">
            {this.formartNewFileIcon(this.nameSfx).indexOf("file.") !== 0 ? (
              <div class="image-box">
                {" "}
                <el-image
                  class="fileIcon"
                  src={
                    message.fileUploadProgress < 100
                      ? this.formartfileIconBase(this.nameSfx)
                      : this.formartNewFileIcon(this.nameSfx)
                  }
                />
                {message.fileUploadProgress < 100 && (
                  <el-progress
                    type="circle"
                    class="imelink-message-file__progress"
                    percentage={message.fileUploadProgress}
                    color="#fff"
                    stroke-width={2}
                    text-inside
                    width={28}
                  />
                )}
                {this.loading && (
                  <el-progress
                    type="circle"
                    class="imelink-message-file__progress"
                    percentage={message.fileUploadProgress}
                    color="#fff"
                    stroke-width={2}
                    text-inside
                    width={28}
                  />
                )}
              </div>
            ) : (
              <div class="folder-box">
                {message.fileUploadProgress < 100 ? (
                  <div class="defaltIcon"></div>
                ) : (
                  <div>
                    <i class="el-icon-folder" />
                    <b>{this.nameSfx}</b>
                  </div>
                )}

                {message.fileUploadProgress < 100 && (
                  <el-progress
                    type="circle"
                    class="imelink-message-file__progress"
                    percentage={message.fileUploadProgress}
                    color="#fff"
                    stroke-width={2}
                    text-inside
                    width={28}
                  />
                )}
                {this.loading && (
                  <el-progress
                    type="circle"
                    class="imelink-message-file__progress"
                    percentage={message.fileUploadProgress}
                    color="#fff"
                    stroke-width={2}
                    text-inside
                    width={28}
                  />
                )}
              </div>
            )}
          </div>
          <div
            class="imelink-message-file__content"
            title={
              message.fileName &&
              message.fileName
                .replace('<font color="red">', "")
                .replace("</font>", "")
            }
          >
            <p
              class="imelink-message-file__name"
              domProps={{
                innerHTML: message.fileName,
              }}
            />
            <p class="imelink-message-file__foot imelink__foot">
              <small>
                {message.fileSize ? toBytesSize(message.fileSize) : ""}
              </small>
            </p>
          </div>
        </div>,
        message.resumeDescription && (
          <div
            class="imelink-message-file__desc"
            onClick={(event) => {
              event.stopPropagation();
              this.jumpPage({
                name: "resume",
                params: { rid: message.id },
              });
            }}
          >
            <small>{message.resumeDescription}</small>
          </div>
        ),
        // <el-progress
        //   type="circle"
        //   class="imelink-message-file__progress"
        //   percentage={message.fileUploadProgress}
        //   color="#1a93ee"
        //   stroke-width={2}
        //   text-inside
        //   width={48}
        // />
      ]),
      //  on{},
    ]);
  },
};
</script>

<style lang="stylus">
@import '~styles/utils/index';
.imelink-message-file__progress {
  position: absolute;
  top: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-box svg path:first-child {
  stroke: rgba(0, 0, 0, 0.2);
}

.file--box svg path:first-child {
  stroke: rgba(0, 0, 0, 0.2);
}

.imelink__foot {
  margin-top: 14px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.down-icon {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dcf5e1;
  color: #52bd68;
  border: none;
}

.down-icon:hover {
  border: none !important;
}

.image-box {
  position: relative;
}

.folder-box {
  position: relative;

  i {
    font-size: 44px;
  }

  b {
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: 1px;
  }

  .defaltIcon{
    width 50px;
    height:54px;
    background:url('../../../../assets/images/newPublish/icon_doc_d.png');
    background-size: cover;
  }
}

.imelink-message-file__progress {
  .el-progress-circle {
    background: transparent !important;
    position: relative;
    margin-top:8px;
  }

  .el-progress-circle::after {
    font-family: iconfont_Me !important;
    content: '\e6aa';
    position: absolute;
    left: 50%;
    top: 50%;
    color: #fff;
    font-size: 16px;
    transform: translate(-50%, -50%);
  }
}

+b(imelink-message--self) {
  +b(imelink-message-file) {
    &::after {
      border-left-color: #e4f0fc !important;
      border-right-color: #ffffff !important;
    }
  }
}

+b(imelink-message-file) {
  background: #e4f0fc !important;
  display: flex;
  flex-flow: column;
  position: relative;
  margin-bottom: 0.9rem !important;
  box-sizing: content-box;

  .file--box {
    display: flex;
    flex-flow: row;
  }

  .imelink-message-file__desc {
    font-size: 12px;
    text-align: left;
    flex: 1;
    max-width: 100%;
    ellipsis();
    color: #777777;
    position: absolute;
    bottom: -25px;
    left: 5px;
  }

  &::after {
    border-right-color: #e4f0fc !important;
  }

  flex-center();

  .fileIcon {
    width: 50px;
    height: 54px;

    img {
      object-fit: cover;
    }
  }

  +e(icon) {
    color: #1a92ed;
    position: relative;
    margin-right: 10px;
    white-space: nowrap;

    >i {
      font-size: 44px;
    }

    >b {
      font-size: 12px;
      position-center();
      margin-top: 1px;
    }
  }

  +e(content) {
    > p {
      line-height: 18px;
      display: block;
      margin: 0;
    }
  }

  +e('name') {
    display: inline-block;
    max-width: 160px;
    ellipsis();
  }

  +e(foot) {
    color: rgba(0, 0, 0, 0.5);
  }

  +e(progress) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .el-progress-bar__innerText {
      display: none;
    }
  }
}

.file--box {
  position: relative;
}

// 取消上传按钮
.cancel {
  position: absolute;
  top: -20px;
  right: -20px;
  // background: #fb474d;
  border-radius: 50%;
  z-index: 10;

  .icon-delete_fill {
    font-size: 25px;
    color: #FB474D;
  }
}
</style>
