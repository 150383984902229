var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll",
          value: {
            distance: 100,
            onTop: _vm._onTop,
            onBottom: _vm._onBottom,
            listenerFn: _vm._listenerFn
          },
          expression:
            "{\n    distance: 100,\n    onTop: _onTop,\n    onBottom: _onBottom,\n    listenerFn: _listenerFn,\n  }"
        }
      ],
      ref: "view",
      staticClass: "imelink-message-view",
      class:
        _vm.$store.state.imStore.otherPartyInfo.encryptStatus === 1
          ? "isEncryptMode"
          : ""
    },
    [
      _vm.control.messageViewState
        ? _c("respon-notice", {
            attrs: { type: _vm.control.messageViewState },
            on: {
              refresh: function($event) {
                _vm.control.messageViewState = "loading"
              }
            }
          })
        : _vm._l(_vm.currentMessage(), function(message, index) {
            return _c(
              "div",
              {
                key: message.id,
                ref: message.sendTime + index,
                refInFor: true,
                class: message.id + index,
                attrs: { id: message.id }
              },
              [
                message.encryptMessage === 1 || message.encryptMessage === 2
                  ? _c("ImelinkMessageEncrypted", {
                      attrs: {
                        downLoadProgress: _vm.downLoadProgress,
                        decryptMessageProgress: _vm.decryptMessageProgress,
                        "message-id": message.id,
                        "message-index": index
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.goodclick(message, $event)
                        }
                      }
                    })
                  : message.type
                  ? _c(`imelink-message-${message.type}`, {
                      tag: "component",
                      attrs: {
                        "message-id": message.id,
                        "message-index": index,
                        languageEnvironment: _vm.languageEnvironment
                      }
                    })
                  : _vm._e(),
                message.startCountdown &&
                message.fromUser.id != _vm.$store.getters.userInfo.id
                  ? _c(
                      "div",
                      {
                        staticClass: "countDown-wrap",
                        class: {
                          self:
                            message.fromUser.id ===
                            _vm.$store.getters.userInfo.id,
                          file:
                            (message.type == "file" ||
                              message.type == "voice") &&
                            !message.encryptMessage
                        }
                      },
                      [
                        _c("div", { staticClass: "countDown" }, [
                          _vm._v(_vm._s(message.startCountdown) + "''")
                        ])
                      ]
                    )
                  : _vm._e(),
                message.messageQuote
                  ? _c(
                      "div",
                      {
                        staticClass: "quote-message-wrap",
                        class: {
                          self: message.fromUser.isGroup
                            ? message.fromUser.selfId ===
                              _vm.$store.getters.userInfo.id
                            : message.fromUser.id ===
                                _vm.$store.getters.userInfo.id ||
                              message.fromUser.selfId ===
                                _vm.$store.getters.userInfo.id
                        }
                      },
                      [
                        message.messageQuote.subType === "text"
                          ? _c(
                              "div",
                              {
                                staticClass: "quote-message quote-text-message",
                                class: {
                                  hasMore:
                                    message.messageQuote.data &&
                                    message.messageQuote.data.length +
                                      _vm.quoteName(message).length >
                                      44
                                }
                              },
                              [
                                message.messageQuote.urlIdentifyContent &&
                                message.messageQuote.urlIdentifyContent.status
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "quote-urlIdentifyContent",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoUrl(
                                              message.messageQuote
                                                .urlIdentifyContent.url
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "text-wrap" },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(_vm.quoteName(message)) +
                                                "："
                                            ),
                                            _c("i", {
                                              staticClass:
                                                "iconfont_Me icon-a-zu10131",
                                              staticStyle: {
                                                "font-size": "16px",
                                                "margin-right": "2px"
                                              }
                                            }),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  message.messageQuote
                                                    .urlIdentifyContent.title
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "img-wrap" },
                                          [
                                            _c(
                                              "el-image",
                                              {
                                                attrs: {
                                                  fit: "cover",
                                                  src:
                                                    message.messageQuote
                                                      .urlIdentifyContent.image
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "error-img",
                                                    attrs: { slot: "error" },
                                                    slot: "error"
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "iconfont_Me icon-link"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            padding: "6px 9px"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-message" },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.quoteName(message)
                                                  ) +
                                                  "：\n                "
                                              ),
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$emoReplaceToImage(
                                                      _vm
                                                        .hyperlinksCheck(
                                                          message.messageQuote
                                                            .data
                                                        )
                                                        .replace(/\n/g, "<br>")
                                                    )
                                                  )
                                                }
                                              })
                                            ]
                                          ),
                                          message.messageQuote.data &&
                                          message.messageQuote.data.length +
                                            _vm.quoteName(message).length >
                                            44
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "more-text",
                                                  on: {
                                                    click: _vm.changeMoreStatus
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "iconfont_Me icon-up-fill"
                                                  })
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      message.messageQuote &&
                                      message.messageQuote.translateMessage &&
                                      _vm.translateMessage(message)
                                        .translateMsg &&
                                      _vm.$store.state.imStore.otherPartyInfo
                                        .characterConvertStatus === 1
                                        ? _c("div", {
                                            staticClass:
                                              "quote_message_text_translate",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$emoReplaceToImage(
                                                  _vm.translateMessage(message)
                                                    .translateMsg
                                                )
                                              )
                                            }
                                          })
                                        : _vm._e()
                                    ])
                              ]
                            )
                          : _vm._e(),
                        message.messageQuote.subType === "pic"
                          ? _c(
                              "div",
                              { staticClass: "quote-message" },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.quoteName(message)) +
                                    "：\n          "
                                ),
                                _c("el-image", {
                                  staticClass: "img",
                                  attrs: {
                                    zIndex: 2022,
                                    "preview-src-list": [
                                      message.messageQuote.url
                                    ],
                                    src: message.messageQuote.url
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        message.messageQuote.subType === "video"
                          ? _c("div", { staticClass: "quote-message" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.quoteName(message)) +
                                  "：\n          "
                              ),
                              _c(
                                "div",
                                { staticClass: "qu-video" },
                                [
                                  _c("el-video", {
                                    staticClass: "video",
                                    staticStyle: { "max-width": "30px" },
                                    attrs: { src: message.messageQuote.url }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        message.messageQuote.subType === "file"
                          ? _c("div", { staticClass: "quote-message" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.quoteName(message)) +
                                  "：\n          "
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "qu-file",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleContentClick(
                                        message.messageQuote
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont_Me icon-a-zu10128",
                                    staticStyle: {
                                      "font-size": "18px",
                                      color: "#aaa",
                                      "margin-right": "1px",
                                      "margin-top": "-2px"
                                    }
                                  }),
                                  _c("div", { staticClass: "file-name" }, [
                                    _vm._v(
                                      _vm._s(message.messageQuote.fileName)
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "file-img" },
                                    [
                                      _c("el-image", {
                                        staticStyle: { height: "100%" },
                                        attrs: {
                                          src: _vm.formartNewFileIcon(
                                            _vm.nameSfx(
                                              message.messageQuote.fileName
                                            )
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        message.messageQuote.subType === "multimedia"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "quote-message quote-voice-message",
                                class: {
                                  hasMore:
                                    message.messageQuote.translateMessage &&
                                    message.messageQuote.translateMessage
                                      .speechConvertMessage &&
                                    message.messageQuote.translateMessage
                                      .speechConvertMessage.length > 44
                                }
                              },
                              [
                                _c("div", { staticClass: "quote-voice-name" }, [
                                  _vm._v(_vm._s(_vm.quoteName(message)) + "：")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "qu-vioce" },
                                  [
                                    _c("qu-voice", {
                                      attrs: { message: message.messageQuote }
                                    })
                                  ],
                                  1
                                ),
                                _c("div", [
                                  message.messageQuote.translateMessage &&
                                  message.messageQuote.translateMessage
                                    .speechConvertMessage
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "qu-voice__speechConvertMessage"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-wrap" },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    message.messageQuote
                                                      .translateMessage
                                                      .speechConvertMessage
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          ),
                                          message.messageQuote.translateMessage
                                            .speechConvertMessage.length > 44
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "more-text",
                                                  on: {
                                                    click: _vm.changeMoreStatus
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "iconfont_Me icon-up-fill"
                                                  })
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e(),
                                  message.messageQuote.translateMessage &&
                                  _vm.$store.state.imStore.otherPartyInfo
                                    .characterConvertStatus === 1
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "qu-voice__speechConvertMessage_translate",
                                          class: {
                                            than50:
                                              message.messageQuote
                                                .translateMessage
                                                .speechConvertMessage &&
                                              message.messageQuote
                                                .translateMessage
                                                .speechConvertMessage.length >
                                                50
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.translateMessage(message) &&
                                                  _vm.translateMessage(message)[
                                                    "translateMsg"
                                                  ]
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            )
                          : _vm._e(),
                        message.messageQuote.serviceType === "group"
                          ? _c(
                              "div",
                              {
                                staticClass: "quote-message",
                                on: {
                                  click: function($event) {
                                    return _vm.go2page(
                                      "group",
                                      message.messageQuote.uuid
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "fs0" }, [
                                  _vm._v(_vm._s(_vm.quoteName(message)))
                                ]),
                                _vm._v("：\n          "),
                                _c("i", {
                                  staticClass: "iconfont_Me icon-Frame-2",
                                  staticStyle: {
                                    "font-size": "14px",
                                    "margin-right": "2px",
                                    "margin-top": "1px",
                                    color: "#666"
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "qu-group ellips_2",
                                    attrs: { title: message.messageQuote.title }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(message.messageQuote.title) +
                                        "\n          "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "qu-group-img" },
                                  [
                                    _c("el-image", {
                                      attrs: {
                                        src: message.messageQuote.picurl
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e(),
                        message.messageQuote.serviceType === "people"
                          ? _c(
                              "div",
                              {
                                staticClass: "quote-message",
                                on: {
                                  click: function($event) {
                                    return _vm.go2page(
                                      "people",
                                      message.messageQuote.uuid
                                    )
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "quote-name" }, [
                                  _vm._v(_vm._s(_vm.quoteName(message)))
                                ]),
                                _vm._v("\n          ：\n          "),
                                _c("div", { staticClass: "qu-people" }, [
                                  _c("i", {
                                    staticClass: "iconfont_Me icon-Frame-1",
                                    staticStyle: {
                                      "font-size": "14px",
                                      "margin-right": "2px",
                                      "margin-top": "2px",
                                      color: "#666"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-message",
                                      attrs: {
                                        title: message.messageQuote.title
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(message.messageQuote.title) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "qu-people-img" },
                                  [
                                    _c("el-image", {
                                      attrs: {
                                        src: message.messageQuote.picurl
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e(),
                        message.messageQuote.serviceType === "service"
                          ? _c(
                              "div",
                              {
                                staticClass: "quote-message",
                                on: {
                                  click: function($event) {
                                    return _vm.go2page(
                                      "service",
                                      message.messageQuote.uuid
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.quoteName(message)) +
                                    "：\n          "
                                ),
                                _c("i", {
                                  staticClass: "iconfont_Me icon-Frame1",
                                  staticStyle: {
                                    "font-size": "14px",
                                    "margin-right": "2px",
                                    "margin-top": "2px",
                                    color: "#666"
                                  }
                                }),
                                _c("div", { staticStyle: { flex: "1" } }, [
                                  _c("div", { staticClass: "text-message" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          message.messageQuote.title.trim() ||
                                            message.messageQuote.briefInfo
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                ]),
                                message.messageQuote.picurl
                                  ? _c(
                                      "div",
                                      { staticStyle: { width: "40px" } },
                                      [
                                        _c("el-image", {
                                          attrs: {
                                            src: message.messageQuote.picurl
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }