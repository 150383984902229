<script>
import basicMixin from "./mixins/basic";
let selectionText;
export default {
  inject: ["control"],
  name: "MessageWebUrl",
  mixins: [basicMixin],
  data() {
    return {
      replaceInit: false,
      translateEdit: false,
      curDom: null,
      keyword: "",
      user: {},
    };
  },
  created() {
    this.setContextMenu();
  },
  mounted() {
    // 强制刷新一下
    this.$forceUpdate();
  },
  methods: {
    // 装填右键菜单操作选项
    setContextMenu() {
      if (
        typeof this.message.text !== "string" ||
        this.message.subType === "link"
      ) {
        this.contextmenu = [];
      } else if (this.message.subType !== "link") {
        this.contextmenu.push({
          icon: "el-icon-document-copy",
          click: () => {
            const el = document.createElement("textarea");
            el.style.position = "absolute";
            el.style.zIndex = "-10";
            el.style.top = "0";
            el.style.left = "0";
            el.style.opacity = "0";
            el.innerHTML = (selectionText || this.message.text).replace(
              /<[^>]*>|<\/[^>]*>/gm,
              ""
            );
            document.body.appendChild(el);
            el.select();
            document.execCommand("Copy");
            el.remove();
          },
        });
      }
    },
    choose(url) {
      if (url.indexOf("melinked.com/#/meeting") != -1) {
        var numArr = url.match(/\d+/g);
        this.$router.push({
          path: `/meeting/${numArr[0]}`,
        });
      } else {
        window.open(url);
      }
    },
  },

  render() {
    const { urlInfos } = this.message;
    let jsonUrlInfos;
    try {
      jsonUrlInfos = JSON.parse(urlInfos);
    } catch {
      jsonUrlInfos = urlInfos;
    }

    return this.elPackage([
      this.elContent([
        urlInfos && (
          <div
            class="url-wrap"
            onClick={() => {
              this.choose(jsonUrlInfos.url);
            }}
          >
            <div class="image-box">
              <el-image class="fileIcon" fit="cover" src={jsonUrlInfos.image}>
                <div slot="error" class="error-img">
                  <i class="iconfont_Me icon-link"></i>
                </div>
              </el-image>
            </div>
            <div class="url-infos">
              <div class="url-title ellipsis2">{jsonUrlInfos.title}</div>
              <div class="url-content ellipsis2">{jsonUrlInfos.content}</div>
            </div>
          </div>
        ),
      ]),
    ]);
  },
};
</script>

<style lang="stylus">
.url-wrap{
  display: flex;
  align-items: center;
  padding: 4px 0;
  .image-box{
    width: 60px;
    height: 58px;
    margin-right: 15px;
    .fileIcon{
      width: 60px;
      height: 58px;
    }
  }
  .url-title{
    font-weight: 700;
    color: #1a051d;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 2px;
  }
  .url-content{
    font-size: 12px;
    color: #666666;
    line-height: 16px;
  }
  .ellipsis2{
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .error-img{
    width: 100%;
    height 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background: #fff;
    i{
      font-size: 45px;
      color:#999
    }
  }
}
</style>
