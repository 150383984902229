<script>
import basicMixin from "./mixins/basic";
import { downloadFile, env } from "@/utils";
export default {
  inject: ["control"],
  name: "MessageImage",
  mixins: [basicMixin],
  created() {
    // 如果是解密后的消息
    if (this.message.isDecrypted && !this.message.isSelfMeassge) {
      this.contextmenu = [];
    } else {
      // 往右键菜单加入下载按钮
      this.contextmenu.push({
        icon: "el-icon-download",
        click: () => downloadFile(this.message.onlineurl || this.message.url),
      });
    }
  },
  mounted() {
    this.handleMessage();
    // 强制刷新一下
    this.$forceUpdate();
    this.$nextTick(() => {
      if (this.message.lightMsg && this.$el.offsetTop - 50) {
        setTimeout(() => {
          this.jumpMessage();
        }, 500);
      }
    });
  },
  methods: {
    // 跳到搜索位置
    jumpMessage() {
      if (!this.message) {
        return;
      }
      try {
        this.control.$refs["messageView"].$refs[
          `${this.message.sendTime + this.messageIndex}`
        ][0].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } catch (error) {
        console.log(error);
      }
    },
    // 搜索消息定位到聊天窗口高亮
    handleMessage() {
      let keyword = localStorage.getItem("catchWord");
      if (this.message.lightMsg && keyword) {
        this.message.fileName = this.message.fileName.replace(
          keyword,
          '<font color="red">' + keyword + "</font>"
        );
      }
    },
    handleContentClick() {
      const self = this;
      let ele = document.querySelector(".imelink-message-view");
      try {
        if (this.control.$refs.messageView.$el) {
          ele = this.control.$refs.messageView.$el;
        }
      } catch (error) {}
      const viewer = this.$viewer(ele, {
        toolbar: {
          download() {
            let downloadUrl = self.formartImageUrl(
              self.message.onlineurl || viewer.image.src
            );
            downloadUrl = `${downloadUrl}&${
              downloadUrl.indexOf("?") != -1 ? "&" : "?"
            }attname=${encodeURIComponent(self.message.fileName)}`;
            downloadFile(encodeURI(downloadUrl), self.message.fileName);
          },
        },
        filter(image) {
          return image.getAttribute("is-viewer-image");
        },
        url(image) {
          let src = image.src;
          if (src.indexOf("2/w/150/h/100") != -1) {
            src = src.replace("/2/w/150/h/100", "/0/interlace/1");
          }
          return src;
        },
        hidden: function() {
          viewer.destroy();
        },
      });
    },
    //取消上传
    cancel() {
      this.subscription.unsubscribe();
      this.$el.parentNode.remove();
    },
  },
  render() {
    return this.elPackage([
      this.elContent(
        <div
          // v-loading={this.isSendGoing()}
          element-loading-background="rgba(0,0,0,1)"
        >
          <thumb
            src={this.formartImageUrl(this.message.url, 0, true)}
            is-viewer-image
            fit="fill"
          />
          {this.message.fileUploadProgress < 100 &&
            this.message.fileUploadProgress > 0 && (
              <div
                class="cancel-img"
                onClick={(event) => {
                  event.stopPropagation();
                  this.cancel();
                }}
              >
                <i class="el-icon-error"></i>
              </div>
            )}
          {this.message.fileUploadProgress < 100 && (
            <el-progress
              type="circle"
              class="imelink-message-file__progress"
              percentage={this.message.fileUploadProgress}
              color="#fff"
              stroke-width={2}
              text-inside
              width={40}
            />
          )}
        </div>,
        { bubble: false }
      ),
    ]);
  },
};
</script>

<style lang="stylus">
@import '~styles/utils/index';

.imelink-message-file__progress {
  position: absolute;
  top: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

+b(imelink-message-image) {
  // overflow: hidden;
  .me-thumb {
    height: 100px;
    min-width: 100px;
    overflow: hidden;
    border-radius: 6px;

    .el-image .el-image__inner {
      width: auto;
    }

    img {
      background: #000;
    }
  }

  .cancel-img {
    position: absolute;
    top: -10px;
    right: -10px;
    // background: #fb474d;
    border-radius: 50%;
    z-index: 10;

    .el-icon-error {
      font-size: 25px;
      color: #FB474D;
    }
  }

  +m(going) {
    .el-image {
      filter: blur(2px);
    }

    .el-loading-spinner .path {
      stroke: rgba(255, 255, 255, 0.8) !important;
    }
  }
}
</style>
