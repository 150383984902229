<script>
import basicMixin from "./mixins/basic";
import Jquery from "jquery";
let selectionText;
import Bus from "@/utils/bus.js";
export default {
  name: "MessageVoice",
  inject: ["control"],
  mixins: [basicMixin],
  data() {
    return {
      // 帧函数标记
      timer: null,
      status: "pause",
      percentage: 0,
      curIndex: null,
      showEditBtns: false,
      curDom: null,
      tryalign: false,
      handlerTry: false,
      user: {},
      jumpTimer: null,
    };
  },
  watch: {
    status(newly) {
      this.$refs.voice[newly]();
    },
  },
  props: {
    languageEnvironment: {
      type: String,
      default: "1",
    },
  },
  created() {
    this.user = this.$store.getters.userInfo;
    // 每次创建都重置一次
    this.pause();
    // 开始监听播放事件
    this.control.$on("messageVoicePlay", (message) => {
      if (this.message && message.id != this.message.id) {
        this.pause();
      }
    });
  },
  mounted() {
    this.handleMessage();
    this.$nextTick(() => {
      if (this.message.lightMsg && this.$el.offsetTop - 50) {
        if (this.jumpTimer) {
          clearTimeout(this.jumpTimer);
        }
        this.jumpTimer = setTimeout(() => {
          this.jumpMessage();
        }, 500);
      }
    });
  },
  methods: {
    // 搜索消息定位到聊天窗口高亮
    handleMessage() {
      let keyword = localStorage.getItem("catchWord");
      if (this.message.lightMsg && keyword) {
        this.message.text = this.message.text.replace(
          keyword,
          '<font color="red">' + keyword + "</font>"
        );
        if (this.message.msgs && this.message.msgs.msgs) {
          this.message.msgs.msgs.map((el) => {
            el.translateMsg = el.translateMsg.replace(
              keyword,
              '<font color="red">' + keyword + "</font>"
            );
          });
        }
      }
    },
    // 跳到搜索位置
    jumpMessage() {
      if (!this.message) {
        return;
      }
      try {
        this.control.$refs["messageView"].$refs[
          `${this.message.sendTime + this.messageIndex}`
        ][0].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } catch (error) {
        console.log(error);
      }
    },
    // 播放录音
    play() {
      this.status = "play";
      this.startProgress();
      // 是加密消息
      if (this.message.isDecrypted) {
        this.control.$emit("messageVoicePlay", this.message);
        Bus.$emit("messageVoiceStartTiming", this.message);
      }
    },
    // 关闭播放录音
    pause() {
      this.status = "pause";
      window.cancelAnimationFrame(this.timer);
      try {
        if (this.$refs.voice && this.$refs.voice.currentTime > 0) {
          this.$refs.voice.currentTime = 0;
        }
        this.percentage = 0;
      } catch (e) {}
    },
    // 点击播放语音
    startProgress() {
      this.frameLoop();
    },
    // 帧循环
    frameLoop() {
      try {
        this.percentage =
          (this.$refs.voice.currentTime / this.message.duration) * 100;
        if (this.percentage >= 100) {
          this.percentage = 100;
          this.pause();
          return;
        }
        if (
          Math.round(this.$refs.voice.currentTime) == this.message.duration &&
          this.message.isDecrypted
        ) {
          Bus.$emit("messageVoiceAudioEnd", this.message);
        }
        this.timer = window.requestAnimationFrame(this.frameLoop);
      } catch (e) {
        this.pause();
      }
    },
    // 播放完毕,停止动画
    audioEnd() {
      // Bus.$emit("messageVoiceAudioEnd", this.message);
      this.pause();
    },
    // 点击当前组件播放
    handleContentClick(e) {
      try {
        if (
          e.target.className &&
          e.target.className.indexOf &&
          (e.target.className.indexOf("el-icon--play") !== -1 ||
            e.target.className.indexOf("el-icon--pause") !== -1)
        ) {
          this.status == "pause" ? this.play() : this.pause();
        } else if (e.path[1].className == "el-progress-circle") {
          this.status == "pause" ? this.play() : this.pause();
        } else if (
          e.target.className.indexOf("imelink-message-voice__translateMsgs") !==
            -1 ||
          e.target.classList[1] === "trans"
        ) {
          try {
            let parent,
              child,
              classList = null;
            if (e.target.classList && e.target.classList[1] === "trans") {
              parent = Jquery(e.target);
            } else {
              parent = Jquery(e.target).parents(".trans");
            }
            child = parent.find(".imelink-message-voice__translateMsgs");
            classList = child[0].classList;
            if (
              !classList.contains("showAll") &&
              classList.contains("hasMore")
            ) {
              child.addClass("showAll");
              parent.addClass("all");
            } else if (
              !classList.contains("edit") &&
              !classList.contains("self")
            ) {
              child.removeClass("showAll");
              parent.removeClass("all");
            }
          } catch (e) {
            console.log("handleContentClick error!!");
          }
        }
      } catch (e) {
        console.log("handleContentClick error!!");
      }
    },
    removeMenu() {
      selectionText = "";
      let removeKey = ["el-icon-document-copy", "el-icon--editw"];
      removeKey.map((key) => {
        this.contextmenu.map((menu, index) => {
          if (menu.icon === key) {
            this.contextmenu.splice(index, 1);
          }
        });
      });

      if (this.message.msgs && !this.message.msgs.speechConvertStatus) {
        this.contextmenu.push({
          icon: "el-icon--editw",
          click: () => {
            this.handlerTry = true;
          },
        });
      }
    },
    resetMenu(index, event) {
      this.curIndex = index;
      this.curDom = Jquery(event.target);
      selectionText = event.target.innerHTML;
      if (
        !this.contextmenu.some(
          (menu) =>
            menu.icon === "el-icon-document-copy" ||
            menu.icon === "el-icon--editw"
        )
      ) {
        this.contextmenu.push(
          {
            icon: "el-icon-document-copy",
            click: () => {
              const el = document.createElement("textarea");
              el.style.position = "absolute";
              el.style.zIndex = "-10";
              el.style.top = "0";
              el.style.left = "0";
              el.style.opacity = "0";
              el.innerHTML = (selectionText || this.message.text).replace(
                /<[^>]*>|<\/[^>]*>/gm,
                ""
              );
              document.body.appendChild(el);
              el.select();
              document.execCommand("Copy");
              el.remove();
            },
          },
          {
            icon: "el-icon--editw",
            click: () => {
              this.transMsg_edit();
            },
          }
        );
      }
    },
    transMsg_edit() {
      this.curDom.hide();
      this.curDom
        .parents(".imelink-message-voice__trans")
        .find(".imelink-message-voice__editBox")
        .show();
      // 隐藏同级的编辑状态
      this.curDom
        .parents(".imelink-message-voice__trans")
        .siblings()
        .find(".imelink-message-voice__translateMsgs")
        .show();
      this.curDom
        .parents(".imelink-message-voice__trans")
        .siblings()
        .find(".imelink-message-voice__editBox")
        .hide();
    },
    voiceTryAlign() {
      this.tryAlignAuto();
    },
    fixTranslate() {
      let newText = this.curDom
        .parents(".imelink-message-voice__trans")
        .find(".imelink-message-voice__editBox")
        .find(".imelink-message-voice__editBox--textarea")
        .val();
      let language = this.curDom
        .parents(".imelink-message-voice__trans")
        .find(".imelink-message-voice__translateMsgs")
        .attr("data-language");
      let index = this.curDom
        .parents(".imelink-message-voice__trans")
        .find(".imelink-message-voice__translateMsgs")
        .attr("data-index");

      // multipleTranslate 语音机器自动转换翻译失败 再次转换 1 ， 人工修改 3 ，人工修改失败 再次尝试 2
      this.emitTranslateChange({
        msgText: newText,
        messageId: this.message.id,
        language,
        multipleTranslate: "3",
        mimeType: "multimedia",
        index,
      });

      // this.$emit("vioceTranslateChange", params);
      this.cancle();
    },

    cancle() {
      this.curDom
        .parents(".imelink-message-voice__trans")
        .find(".imelink-message-voice__editBox")
        .hide();
      this.curDom
        .parents(".imelink-message-voice__trans")
        .find(".imelink-message-voice__translateMsgs")
        .show();
    },
    clearMenu() {
      this.contextmenu.splice(1, 2);
      selectionText = "";
    },
    set_DomFocus(target) {
      target.focus();
      var range = document.createRange();
      range.selectNodeContents(target);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    },
    tryalignTransText(event) {
      let index = Jquery(event.target).attr("data-index");
      let language = Jquery(event.target).attr("data-language");
      this.message.msgs.msgs[index].updataisGoing = true;
      this.updataTranslageMsgGoing(index);
      const data = {
        msgText: "",
        messageId: this.message.id,
        language,
        multipleTranslate: "2",
        mimeType: "multimedia",
        index,
      };
      this.emitTranslateChange(data);
    },
    tryalignCancle() {
      this.handlerTry = false;
    },
    tryalignSend(event) {
      let index = 0;
      let text = Jquery(event.target)
        .parents(".handler-tryalign")
        .find(".imelink-message-voice__editBox--textarea")
        .val();
      // let language = Jquery(event.target).attr("data-language");
      this.message.msgs = {
        msgs: [{}],
      };
      this.message.msgs.msgs[index].updataisGoing = true;
      this.updataTranslageMsgGoing(index);
      const data = {
        msgText: text,
        messageId: this.message.id,
        language: -1,
        multipleTranslate: "1",
        mimeType: "multimedia",
        index,
      };
      this.tryalignCancle();
      this.emitTranslateChange(data);
    },
    handlerResetHeight(index) {
      let target = "textarea_" + index;
      const dom = this.$refs[target];
      dom.style.height = dom.scrollHeight + "px";
    },
  },
  render() {
    //  style={{
    //     width: this.message.duration * 10 + "px"
    //   }}
    const { msgs } = this.message;
    let translist = [];
    let selfTransmsg = {};
    if (msgs && msgs.msgs) {
      msgs.msgs.map((item) => {
        if (
          item.language !== this.message.msgs.sourceLanguage &&
          item.translateMsg &&
          this.$emoReplaceToImage(item.translateMsg) &&
          this.control.currentContact.code &&
          item.language == this.languageEnvironment
        ) {
          selfTransmsg = { ...item, self: true };
        } else {
          translist.push(item);
        }
        // 取消默认展开第一条
        // if (item.language !== this.message.msgs.sourceLanguage)
      });
      // 只显示一种语言
      let localLangCode = localStorage.getItem("langCode");
      translist = translist.filter((item) => {
        return item.language == localLangCode;
      });
      if (Object.keys(selfTransmsg).length) translist.unshift(selfTransmsg);
    }
    return this.elPackage([
      this.elContent([
        <div class="imelink-message-voice" onMousedown={this.removeMenu}>
          <el-progress
            type="circle"
            class="imelink-message-voice__progress"
            percentage={this.percentage > 100 ? 100 : this.percentage}
            color="#33cc66"
            stroke-width={2}
            width={35}
            show-text={false}
          />
          <i
            v-show={this.status == "pause"}
            class="imelink-message-voice__icon el-icon--play"
          />
          <i
            v-show={this.status == "play"}
            class="imelink-message-voice__icon el-icon--pause"
          />

          <audio
            preload="auto"
            src={this.formartImageUrl(this.message.url)}
            onEnded={this.audioEnd.bind(this)}
            ref="voice"
          />
          <div class="imelink-message-voice__duration">
            {this.message.duration}''
          </div>
          {this.message.tryalign && (
            <div class="imelink-message-voice__editBox tryalign">
              <textarea
                class="imelink-message-voice__editBox--textarea"
                autofocus
              />
              <div class="imelink-message-voice__translateMsgs__btns">
                <i class="el-icon-close icon" onClick={this.cancle} />
                <i class="el-icon-check icon" onClick={this.fixTranslate} />
              </div>
            </div>
          )}
          {this.message.msgs && this.message.msgs.speechConvertStatus ? (
            <div class="imelink-message-voice__speechConvertMessage">
              {this.message.msgs.speechConvertMessage}
            </div>
          ) : (
            ""
            // <i
            //   class="el-icon-warning imelink-message-voice__errorIcon"
            //   onMousedown={this.voiceTryAlign}
            // />
          )}
        </div>,
        !this.message.disshowMsgs && (
          <div class="imelink-message-voice trans_list">
            {translist.length
              ? translist.map((item, index) => {
                  if (
                    item.language !== this.message.msgs.sourceLanguage &&
                    item.translateMsg
                  )
                    return (
                      <div
                        class={[
                          "imelink-message-voice__trans",
                          {
                            trans:
                              !item.self &&
                              item.translateMsg.length >
                                (item.language !== 8 ? 60 : 50),
                          },
                        ]}
                        onMousedown={this.resetMenu.bind(this, index)}
                      >
                        {item.updataisGoing && <i class="el-icon-loading" />}
                        <div
                          class={[
                            "imelink-message-voice__translateMsgs",
                            {
                              hasMore:
                                !item.self &&
                                item.translateMsg.length >
                                  (item.language !== 8 ? 60 : 50),
                            },
                            { self: item.self },
                            {
                              showAll:
                                index == 0 ||
                                item.translator_id == this.user.id,
                              // item.translateMsg == this.message.translate
                            },
                          ]}
                          data-language={item.language}
                          data-index={index}
                          data-self={
                            item.self || item.translator_id == this.user.id
                          }
                        >
                          {item.translateMsg}
                        </div>
                        {item.translator_id != 2 && (
                          <avatar
                            class="imelink-message-translate__avatar"
                            size="22px"
                            src={this.getAuthorAvatar(item.translator_id) || ""}
                          />
                        )}
                        <div
                          class="imelink-message-voice__editBox"
                          style=" display: none;"
                        >
                          <textarea
                            class="imelink-message-voice__editBox--textarea"
                            autofocus
                            ref={`textarea_${index}`}
                            onFocus={this.handlerResetHeight.bind(this, index)}
                          >
                            {item.translateMsg.replace(/<\/?.+?\/?>/g, "")}
                          </textarea>
                          <div class="imelink-message-voice__translateMsgs__btns">
                            <i
                              class="el-icon-close icon"
                              onClick={this.cancle}
                            />
                            <i
                              class="el-icon-check icon"
                              onClick={this.fixTranslate}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  else if (item.language !== this.message.msgs.sourceLanguage) {
                    return (
                      <div
                        class="imelink-message-text__error"
                        data-language={item.language}
                        data-index={index}
                        onClick={this.tryalignTransText}
                      >
                        <i
                          data-language={item.language}
                          data-index={index}
                          class={[
                            item.updataisGoing
                              ? "el-icon-loading"
                              : "el-icon-warning",
                            "errorIcon",
                          ]}
                        />
                      </div>
                    );
                  }
                })
              : ""}
          </div>
        ),
        this.handlerTry && (
          <div class="handler-tryalign">
            <textarea
              class="imelink-message-voice__editBox--textarea"
              autofocus
            />
            <div class="imelink-message-voice__translateMsgs__btns">
              <i class="el-icon-close icon" onClick={this.tryalignCancle} />
              <i class="el-icon-check icon" onClick={this.tryalignSend} />
            </div>
          </div>
        ),
      ]),
    ]);
  },
};
</script>

<style lang="stylus">
@import '~styles/utils/index';
@import '~styles/utils/mixins';

.tryalign {
  position: absolute;
  top: 50px;
  z-index: 2000;
}
.imelink-message-voice{
  .imelink-message-voice__progress{
    .el-progress-circle{
      svg{
        path{
          transition: none !important;
        }
      }
    }
  }
}
.imelink-message--self {
  .imelink-message-text__translate {
    border-top-color: #fff;
  }

  .imelink-message-text__translate-edit {
    right: auto;
    left: -30px;
    top: 8px;
  }

  .imelink-message-translate__avatar {
    right: auto;
    left: -30px;
  }
}

.imelink-message-translate__avatar {
  position: absolute;
  right: -30px;

  // bottom: 3px;
  .me-avatar__error {
    font-size: 12px;
  }
}

.el-progress-circle {
  background: #ffffff;
  border-radius: 5rem;
}

+b(imelink-message-voice--succeed) {
  flex-flow: column;
  align-items: flex-start !important;
  justify-content: flex-start;
}

+b(imelink-message-voice) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 2px !important;
  position: relative;
  font-size: 12px;

  +e(editBox) {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-end;

    +m(textarea) {
      width: 100%;
      display: inline-block;
      min-height: 100px;
      // max-height: 200px;
      border: 0;
      padding: 0.25rem;
      // overflow-y: scroll;
      transition: all 200ms linear;
      scrollbar-light();
    }
  }

  +m(editBtns) {
    position: absolute;
    right: 0;
    bottom: 15px;

    .icon {
      margin: 0 0.5rem 0;
      padding: 0 0.5rem;
      border-radius: 3px;
      color: #ffffff;
      font-size: 18px;

      &.el-icon-close {
        background: #999999;
      }

      &.el-icon-check {
        background: #33cc66;
      }
    }
  }

  +e(errorIcon) {
    font-size: 22px;
    vertical-align: middle;
    margin-left: 0.5rem;

    &.el-icon-warning {
      color: red;
    }
  }

  +e(icon) {
    color: #666;
    font-size: 16px;
    vertical-align: middle;
    position: absolute;
    left: 0.9rem;

    &.el-icon--pause {
      color: #33cc66;
    }

    &.el-icon-warning {
      color: red;
      font-size: 20px;
    }
  }

  +e(duration) {
    font-size: 12px;
    color: #666;
  }

  +e(progress) {
    margin: 0 5px 0 0;
    min-width: 40px;
    max-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-progress-bar__inner {
      transition: none;
    }

    .el-progress-bar__outer {
      background: #ddd;
    }
  }

  +e(speechConvertMessage) {
    margin: 0 10px;
  }

  &.trans_list {
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: flex-start;
    padding: 0px 10px !important;
  }

  +e(trans) {
    width: 100%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    border-top: 1px solid #ffffff;
    display: flex;
    align-items: flex-start;

    i {
      margin-right: 0.3rem;
      font-size: 18px;
    }
      &.trans{
      &::after {
            font-family: 'icons' !important;
            content: '\e694';
            // position: absolute;
            // margin-left: 5px;
            margin-top 13px
            // padding-left 5px;
            // right: -1px;
            // top: 5px;
            // border: 1px solid #e5e5e5;
            text-align: center;
            border-radius: 20px;
            background: #ffffff;
            line-height: 15px;
            color: #999999;
            font-size: 16px;
            cursor pointer
          }
    }
    &.all{
      &::after {
        font-family: 'icons' !important;
        content: '\e80a' !important;
      }
    }
  }

  +e(translateMsgs) {
    flex: 1;
    padding 5px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // word-wrap: break-word;
    position: relative;
    display: inline-block;
    cursor: text;

    &.showAll {
      white-space: normal;
      height: auto;
      display: flex;
      word-break: break-word;

      // &.hasMore {
      //   &::after {
      //     top: auto !important;
      //     bottom: 3px;
      //     font-family: 'icons' !important;
      //     content: '\e80a' !important;
      //   }
      // }
    }

    &.edit {
      background: #ffffff;
    }

    // &.hasMore {
    //   &::after {
    //     font-family: 'icons' !important;
    //     content: '\e694';
    //     position: absolute;
    //     // margin-left: 0.9rem;
    //     right: -1px;
    //     top: 12px;
    //     // border: 1px solid #e5e5e5;
    //     text-align: center;
    //     border-radius: 20px;
    //     background: #ffffff;
    //     line-height: 15px;
    //     color: #999999;
    //     font-size: 16px;
    //   }
    // }

    +e(btns) {
      font-size: 15px;
      display: flex;
      flex-flow: row;
      margin-top: 0.5rem;

      i {
        padding: 0.1rem 0.6rem;
        background: #ffffff;
        margin: 0 0.5rem 0 0;
        border-radius: 3px;

        &.el-icon-check {
          background: #33cc66;
          color: #ffffff;
        }

        &.el-icon-close {
          background: #9da2a6;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
