<script>
import basicMixin from "./mixins/basic";
import Bus from "../../../../utils/bus.js";
export default {
  inject: ["control"],
  name: "MessageText",
  mixins: [basicMixin],
  data() {
    return {
      replaceInit: false,
      translateEdit: false,
      curDom: null,
      keyword: "",
      user: {},
    };
  },
  created() {
    this.user = this.$store.getters.userInfo;
    this.setContextMenu();
  },
  mounted() {
    // 强制刷新一下
    this.$forceUpdate();
    this.$nextTick(() => {
      if (this.message.lightMsg && this.$el.offsetTop - 50) {
        setTimeout(() => {
          this.jumpMessage();
        }, 500);
      }
    });
  },
  methods: {
    // 跳到搜索位置
    jumpMessage() {
      if (!this.message) {
        return;
      }
      this.control.$refs["messageView"].$refs[
        `${this.message.sendTime + this.messageIndex}`
      ][0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    // 装填右键菜单操作选项
    setContextMenu() {
      if (
        typeof this.message.text !== "string" ||
        this.message.subType === "link"
      ) {
        this.contextmenu = [];
      } else if (this.message.subType !== "link") {
        this.contextmenu = [];
      }
    },
    cancle() {
      if (this.curDom) {
        this.curDom.find(".imelink-message-text__editBox").hide();
        this.curDom.find(".imelink-message-text__translateMsg").show();
      }
    },
    choose(item) {
      this.$store.commit("setQsData", item);
      Bus.$emit("qsData", item);
    },
  },

  render() {
    const { qs, welcomes } = this.message;
    return this.elPackage([
      this.elContent([
        <div class="qs-welcomes">{welcomes}</div>,
        <div class="qs-list">
          {qs &&
            qs.map((item) => {
              return (
                <div
                  class="qs-item"
                  onClick={() => {
                    this.choose(item);
                  }}
                >
                  {item.question}
                </div>
              );
            })}
        </div>,
      ]),
    ]);
  },
};
</script>

<style lang="stylus">
@import '~styles/utils/index';

.errorIcon {
  font-size: 20px;
  margin-left: 0.5rem;

  &.el-icon-warning {
    color: red;
  }

  &.el-icon-loading {
    color: #999999;
  }
}

.imelink-message-text {
  padding: 0 !important;
  display: flex;
  flex-flow: column;

  &.imelink-message__content {
    font-size: 12px;
  }

  +e(error) {
    cursor: pointer;
  }

  +e(editBox) {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-end;

    +m(textarea) {
      width: 100%;
      display: inline-block;
      line-height: 22px;
      min-height: 100px;
      // max-height: 200px;
      border: 0;
      padding: 0.25rem;
      // overflow-x: hidden;
      transition: all 200ms linear;
      // height -webkit-fill-available
      overflow-y: scroll;
      scrollbar-light();
    }
  }

  +e(oldTranslate) {
    background: #c6edcc;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }

  +e(translate) {
    &.trans {
      &::after {
        font-family: 'icons' !important;
        content: '\e694';
        // position: absolute;
        // margin-left: 5px;
        margin-top: 5px;
        // padding-left 5px;
        // right: -1px;
        // top: 5px;
        // border: 1px solid #e5e5e5;
        text-align: center;
        border-radius: 20px;
        background: #ffffff;
        line-height: 15px;
        color: #999999;
        font-size: 16px;
        cursor: pointer;
      }
    }

    &.all {
      &::after {
        font-family: 'icons' !important;
        content: '\e80a' !important;
      }
    }

    &.trans_list {
      display: flex;
      align-items: flex-start;
      background: none;
      border-top: 1px solid #ffffff;
      width: 100%;

      .imelink-message-text__translateMsg {
        flex: 1;
        // padding: 0.5rem 12px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        // display: flex;
        flex-flow: row;
        cursor: default;
        max-height: 24px;

        &.showAll {
          white-space: normal;
          height: auto;
          display: flex;
          word-break: break-word;
          display: inline;
          max-height: none !important;

          img {
            float: left;
          }

          &.hasMore {
          }
        }

        &.edit {
          background: #ffffff;
        }

        +e(btns) {
          font-size: 15px;
          display: flex;
          flex-flow: row;
          margin-top: 0.5rem;

          i {
            padding: 0.1rem 0.6rem;
            background: #ffffff;
            margin: 0 0.5rem 0 0;
            border-radius: 3px;
            cursor: pointer;

            &.el-icon-check {
              background: #33cc66;
              color: #ffffff;
            }

            &.el-icon-close {
              background: #9da2a6;
              color: #ffffff;
            }
          }
        }
      }

      // border-radius: 5px;
      &:last-child {
        // border 0
      }

      .el-icon-loading {
        margin-right: 0.3rem;
        font-size: 18px;
      }
    }
  }

  +m(editBtns) {
    position: absolute;
    right: 0;
    bottom: 15px;

    .icon {
      margin: 0 0.5rem 0;
      padding: 0 0.5rem;
      border-radius: 3px;
      color: #ffffff;
      font-size: 18px;

      &.el-icon-close {
        background: #999999;
      }

      &.el-icon-check {
        background: #33cc66;
      }
    }
  }
}

+b(imelink-message-text) {
  display: inline-block;
  word-wrap: break-word;
  word-break: break-word;
  cursor: text !important;
  line-height: 22px !important;

  img {
    vertical-align: middle;
    // height: 22px;
    position: relative;
    top: -2px;
  }

  +e(translateMsgs) {
    flex: 1;
    // padding: 0.5rem 12px;
    width: 100%;
    overflow: hidden;
    // text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    display: flex;
    flex-flow: row;
    cursor: default;
    padding: 0 5px;

    &.showAll {
      white-space: normal;
      height: auto;
      display: flex;
      word-break: break-all;
      display: inline;

      &::after {
        top: auto;
        bottom: 3px;
        font-family: 'icons' !important;
        content: '\e80a';
      }
    }

    &.edit {
      background: #ffffff;
    }

    &::after {
      font-family: 'icons' !important;
      content: '\e694';
      position: absolute;
      margin-left: 0.9rem;
      right: 1px;
      top: 14px;
      // border: 1px solid #e5e5e5;
      text-align: center;
      border-radius: 20px;
      background: #ffffff;
      line-height: 15px;
      color: #999999;
      font-size: 20px;
    }

    +e(btns) {
      font-size: 15px;
      display: flex;
      flex-flow: row;
      margin-top: 0.5rem;

      i {
        padding: 0.1rem 0.6rem;
        background: #ffffff;
        margin: 0 0.5rem 0 0;
        border-radius: 3px;
        cursor: pointer;

        &.el-icon-check {
          background: #33cc66;
          color: #ffffff;
        }

        &.el-icon-close {
          background: #9da2a6;
          color: #ffffff;
        }
      }
    }
  }
}

.imelink-message-text {
  .imelink-message-text__translate {
    padding: 8px 12px;
    box-sizing: border-box;
    outline: none;
  }

  hr {
    border: 0;
    height: 1px;
    background: #90cf99;
    margin: 10px 0;

    &:last-child {
      display: none;
    }
  }

  .imelink-message-text__translate-edit {
    left: auto;
    right: -30px;
    top: 8px;
    position: absolute;
    color: #52bd68;
    font-size: 16px;
    cursor: pointer;
    padding: 4px;
    z-index: 1;
  }
}

.imelink-message-text__translate.imelink-message-text__translate--edit {
  border: 1px solid #4ece6c;
  background: #fff;
  min-width: 120px;
}

.imelink-message-text__translate-action {
  position: absolute;
  bottom: -34px;
  right: 0;
  z-index: 1;
}

.imelink-message--self {
  .imelink-message-text__translate {
    border-top-color: #fff;
    background: #e6f5e9; // #c6edcc;
  }

  .imelink-message-text__translate-edit {
    right: auto;
    left: -30px;
    top: 8px;
  }

  .imelink-message-translate__avatar {
    right: auto;
    left: -30px;
  }
}

.imelink-message-translate__avatar {
  position: absolute;
  right: -30px;

  // bottom: 3px;
  .me-avatar__error {
    font-size: 12px;
  }
}

.qs-item {
  padding: 3px 0;

  &:hover {
    color: #52bd68;
  }
}

// .qs-list{
// position: absolute;
// top: 50px;
// left: -0px;
// background: #f1f1f1;
// width: 380px;
// border-radius: 6px;
// padding: 10px 15px;
// }
.imelink-message-qs {
  padding: 0 !important;
  background: #fff !important;
  font-size:12px;
  .qs-welcomes {
    font-size:12px;
    padding: 10px 15px;
    width: fit-content;
    background: #f1f1f1;
    border-radius: 6px;
  }

  .qs-list {
    // min-width: 80%;
    // width: fit-content;
    margin-left: -40px;
    margin-top: 6px;
    padding: 10px 15px;
    border-radius: 6px;
    background: #f1f1f1;
    .qs-item{
      font-size: 12px;
    }
  }
}
</style>
