<template>
  <div>
    <div class="qu-message-voice" @click="handleContentClick($event)">
      <el-progress
        type="circle"
        class="qu-message-voice__progress"
        :percentage="percentage"
        color="#33cc66"
        :stroke-width="1"
        :width="18"
        :show-text="false"
      />
      <i
        v-show="status == 'pause'"
        class="qu-message-voice__icon el-icon--play"
      ></i>
      <i
        v-show="status == 'play'"
        class="qu-message-voice__icon el-icon--pause"
      ></i>
      <audio
        preload="auto"
        :src="message.url"
        @ended="audioEnd()"
        ref="qvoice"
      ></audio>
      <div class="imelink-message-voice__duration">
        {{ message.multimediaLength }}''
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "quVoice",
  data() {
    return {
      url:
        "https://tfile.melinked.com/image_20221104161000993663/55fa286c-0d1d-4444-bc25-e1e3bea97ecc.wav",
      duration: 2,
      timer: null,
      status: "play",
      percentage: 0,
      curIndex: null,
      showEditBtns: false,
      curDom: null,
      tryalign: false,
      handlerTry: false,
      user: {},
    };
  },
  watch: {
    status(newly) {
      this.$refs.qvoice[newly]();
    },
  },
  props: {
    message: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.$forceUpdate();
  },
  created() {
    this.user = this.$store.getters.userInfo;
    // 每次创建都重置一次
    this.pause();
  },
  methods: {
    // 关闭播放录音
    pause() {
      this.status = "pause";
      window.cancelAnimationFrame(this.timer);
      try {
        if (this.$refs.qvoice && this.$refs.qvoice.currentTime > 0) {
          this.$refs.qvoice.currentTime = 0;
        }
        this.percentage = 0;
      } catch (e) {}
    },
    // 播放录音
    play() {
      this.status = "play";
      this.startProgress();
    },
    // 点击播放语音
    startProgress() {
      this.frameLoop();
    },
    // 帧循环
    frameLoop() {
      try {
        this.percentage =
          (this.$refs.qvoice.currentTime /
            (this.message.duration || this.message.multimediaLength)) *
          100;
        if (this.percentage >= 100) {
          this.percentage = 100;
          this.pause();
          return;
        }
        this.timer = window.requestAnimationFrame(this.frameLoop);
      } catch (e) {
        this.pause();
      }
    },
    // 播放完毕,停止动画
    audioEnd() {
      this.pause();
    },
    // 点击当前组件播放
    handleContentClick() {
      try {
        this.status == "pause" ? this.play() : this.pause();
      } catch (e) {
        console.log("handleContentClick error!!");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.qu-message-voice {
  display: flex;
  position: relative;
  font-size: 12px;
  align-items: center;
  cursor: pointer;

  .qu-message-voice__icon {
    color: #666;
    font-size: 12px;
    vertical-align: middle;
    position: absolute;
    left: 0.2rem;
    top: 50%;
    transform: translateY(-50%) scale(0.8);
  }

  .qu-message-voice__progress {
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
  }
}
</style>
