var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "qu-message-voice",
        on: {
          click: function($event) {
            return _vm.handleContentClick($event)
          }
        }
      },
      [
        _c("el-progress", {
          staticClass: "qu-message-voice__progress",
          attrs: {
            type: "circle",
            percentage: _vm.percentage,
            color: "#33cc66",
            "stroke-width": 1,
            width: 18,
            "show-text": false
          }
        }),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.status == "pause",
              expression: "status == 'pause'"
            }
          ],
          staticClass: "qu-message-voice__icon el-icon--play"
        }),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.status == "play",
              expression: "status == 'play'"
            }
          ],
          staticClass: "qu-message-voice__icon el-icon--pause"
        }),
        _c("audio", {
          ref: "qvoice",
          attrs: { preload: "auto", src: _vm.message.url },
          on: {
            ended: function($event) {
              return _vm.audioEnd()
            }
          }
        }),
        _c("div", { staticClass: "imelink-message-voice__duration" }, [
          _vm._v("\n      " + _vm._s(_vm.message.multimediaLength) + "''\n    ")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }