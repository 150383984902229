<script>
import basicMixin from "./mixins/basic";
import { isString } from "utils/validate";
export default {
  name: "MessageNotice",
  inject: ["control"],
  mixins: [basicMixin],
  created() {
    this.message.fromUser = this.message.fromUser || {};
  },
  mounted() {
    // console.log('notice')
    this.$nextTick(() => {
      if (this.message.lightMsg && this.$el.offsetTop - 50) {
        setTimeout(() => {
          this.jumpMessage();
        }, 500);
      }
    });
  },
  methods: {
    // 跳到搜索位置
    jumpMessage() {
      if (!this.message) {
        return;
      }
      this.control.$refs["messageView"].$refs[
        `${this.message.sendTime + this.messageIndex}`
      ][0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
  render() {
    const { sendTime, text } = this.message;
    return (
      <div>
        <div
          class="imelink-message imelink-message-notice"
          style="margin-bottom: 5px"
        >
          <el-tag
            type="info"
            class="note-msg"
            title={sendTime && this._parseTime(sendTime)}
          >
            {sendTime && this._parseTime(sendTime)}
          </el-tag>
        </div>
        <div class="imelink-message imelink-message-notice">
          <el-tag
            type="info"
            class="note-msg"
            title={sendTime && this._parseTime(sendTime)}
          >
            {isString(text) ? <span domProps={{ innerHTML: text }} /> : text}
          </el-tag>
        </div>
      </div>
    );
  },
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

+b(imelink-message-notice) {
  text-align: center;
  flex-center();

  .el-tag {
    color: #999 !important;
  }

  small {
    color: #aaa;
    padding-right: 8px;
  }

  .el-tag {
    max-width: 300px;
    ellipsis();
    color: #333;
    height: auto;
    white-space: pre-wrap;
    line-height: 22px;

    .note-msg {
      & > span {
        width: 100%;
        word-break: break-all;
        line-height: 24px !important;
      }
    }
  }

  img {
    height: 12px;
    position: relative;
    top: 1px;
    padding: 0 6px;
  }
}
</style>
