import CryptoJS from "crypto-js";


const uuid = () => {
    return 'xxxxxxxx-xxxx-xy'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};



export const AESKey =  uuid();
export const AESIv = AESKey;


export const WordArrayToArrayBuffer = wordArray => {
    const { words } = wordArray;
    const { sigBytes } = wordArray;
    const u8 = new Uint8Array(sigBytes);
    for (let i = 0; i < sigBytes; i += 1) {
        const byte = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
        u8[i] = byte;
    }
    return u8;
}

export const ArrayBufferToWordArray = arrayBuffer => {
    const u8 = new Uint8Array(arrayBuffer, 0, arrayBuffer.byteLength);
    const len = u8.length;
    const words = [];
    for (let i = 0; i < len; i += 1) {
        words[i >>> 2] |= (u8[i] & 0xff) << (24 - (i % 4) * 8);
    }
    return CryptoJS.lib.WordArray.create(words, len);
}

export const AESEncData = (data, key, iv) => { // 这里的data是WordBuffer类型的数据
    // const bKey = CryptoJS.enc.Hex.parse(key);
    const bKey = CryptoJS.enc.Utf8.parse(key);
    // const bIv = CryptoJS.enc.Hex.parse(iv);
    const bIv = CryptoJS.enc.Utf8.parse(iv);

    const encrypt = CryptoJS.AES.encrypt(data, bKey, { iv: bIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    const arrayBuffer = WordArrayToArrayBuffer(encrypt.ciphertext);
    return arrayBuffer;
}

export const AESDecData = (data, key, iv) => { // 这里的data是WordBuffer类型的数据
    // const bKey = CryptoJS.enc.Hex.parse(key);
    const bKey = CryptoJS.enc.Utf8.parse(key);
    // const bIv = CryptoJS.enc.Hex.parse(iv);
    const bIv = CryptoJS.enc.Utf8.parse(iv);
    const decrypt = CryptoJS.AES.decrypt({ ciphertext: data }, bKey, { iv: bIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    const arrayBuffer = WordArrayToArrayBuffer(decrypt);
    return arrayBuffer;
}
