<script>
import basicMixin from "./mixins/basic";
import { downloadFile } from "@/utils";
export default {
  name: "MessageVideo",
  inject: ["control"],
  mixins: [basicMixin],
  data() {
    return {
      videoReady: false,
      jumpTimer: null,
    };
  },
  created() {
    // 如果是解密后的消息
    if (this.message.isDecrypted && !this.message.isSelfMeassge) {
      this.contextmenu = [];
    } else {
      this.contextmenu.push({
        icon: "el-icon-download",
        click: () => downloadFile(this.message.url, this.message.fileName),
      });
    }
  },
  mounted() {
    this.handleMessage();
    this.$nextTick(() => {
      if (this.message.lightMsg && this.$el.offsetTop - 50) {
        if (this.jumpTimer) {
          clearTimeout(this.jumpTimer);
        }
        this.jumpTimer = setTimeout(() => {
          this.jumpMessage();
        }, 500);
      }
    });
  },
  methods: {
    // 跳到搜索位置
    jumpMessage() {
      if (!this.message) {
        return;
      }
      try {
        this.control.$refs["messageView"].$refs[
          `${this.message.sendTime + this.messageIndex}`
        ][0].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } catch (error) {
        console.log(error);
      }
    },
    // 搜索消息定位到聊天窗口高亮
    handleMessage() {
      let keyword = localStorage.getItem("catchWord");
      if (this.message.lightMsg && keyword) {
        this.message.fileName = this.message.fileName.replace(
          keyword,
          '<font color="red">' + keyword + "</font>"
        );
      }
    },
    //取消上传
    cancel() {
      this.subscription.unsubscribe();
      this.$el.parentNode.remove();
    },
  },
  render() {
    return this.elPackage([
      this.elContent(
        [
          <div element-loading-background="rgba(0,0,0,0.6)">
            <el-video
              src={this.message.url}
              isDecrypted={this.message.isDecrypted}
              alert-title={this.message.fizeName}
              messageId={this.message.id}
            />
            {this.message.fileUploadProgress < 100 &&
              this.message.fileUploadProgress > 0 && (
                <div
                  class="cancel-img"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.cancel();
                  }}
                >
                  <i class="el-icon-error"></i>
                </div>
              )}
            {this.message.fileUploadProgress < 100 && (
              <el-progress
                type="circle"
                class="imelink-message-file__progress"
                percentage={this.message.fileUploadProgress}
                color="#fff"
                stroke-width={2}
                text-inside
                width={40}
              />
            )}
          </div>,
        ],
        { bubble: false }
      ),
    ]);
  },
};
</script>

<style lang="stylus">
@import '~styles/utils/index';

.imelink-message-file__progress {
  position: absolute;
  top: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

+b(imelink-message-video) {
  // overflow: hidden;
  .cancel-img {
    position: absolute;
    top: -10px;
    right: -10px;
    // background: #fb474d;
    border-radius: 50%;
    z-index: 10;

    .el-icon-error {
      font-size: 25px;
      color: #FB474D;
    }
  }

  .me-video {
    border-radius: 6px !important;
    overflow: hidden;
  }

  +m(going) {
    video {
      filter: blur(2px);
    }

    .me-video__play {
      display: none;
    }

    .el-loading-spinner .path {
      stroke: rgba(255, 255, 255, 0.8) !important;
    }
  }
}
</style>
