import { render, staticRenderFns } from "./quVoice.vue?vue&type=template&id=4492d37e&scoped=true"
import script from "./quVoice.vue?vue&type=script&lang=js"
export * from "./quVoice.vue?vue&type=script&lang=js"
import style0 from "./quVoice.vue?vue&type=style&index=0&id=4492d37e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4492d37e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\main project\\new_front_melinked\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4492d37e')) {
      api.createRecord('4492d37e', component.options)
    } else {
      api.reload('4492d37e', component.options)
    }
    module.hot.accept("./quVoice.vue?vue&type=template&id=4492d37e&scoped=true", function () {
      api.rerender('4492d37e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/imelink/message/quVoice.vue"
export default component.exports