<template>
  <div
    class="imelink-message-view"
    :class="
      $store.state.imStore.otherPartyInfo.encryptStatus === 1
        ? 'isEncryptMode'
        : ''
    "
    ref="view"
    v-scroll="{
      distance: 100,
      onTop: _onTop,
      onBottom: _onBottom,
      listenerFn: _listenerFn,
    }"
  >
    <respon-notice
      v-if="control.messageViewState"
      @refresh="control.messageViewState = 'loading'"
      :type="control.messageViewState"
    />
    <template v-else>
      <div
        v-for="(message, index) in currentMessage()"
        :key="message.id"
        :class="message.id + index"
        :id="message.id"
        :ref="message.sendTime + index"
      >
        <!-- 加密消息组件 -->
        <ImelinkMessageEncrypted
          @click.native="goodclick(message, $event)"
          v-if="message.encryptMessage === 1 || message.encryptMessage === 2"
          :downLoadProgress="downLoadProgress"
          :decryptMessageProgress="decryptMessageProgress"
          :message-id="message.id"
          :message-index="index"
        />
        <component
          v-else-if="message.type"
          :is="`imelink-message-${message.type}`"
          :message-id="message.id"
          :message-index="index"
          :languageEnvironment="languageEnvironment"
        >
        </component>
        <!-- 消息销毁倒计时 -->
        <div
          class="countDown-wrap"
          v-if="
            message.startCountdown &&
              message.fromUser.id != $store.getters.userInfo.id
          "
          :class="{
            self: message.fromUser.id === $store.getters.userInfo.id,
            file:
              (message.type == 'file' || message.type == 'voice') &&
              !message.encryptMessage,
          }"
        >
          <div class="countDown">{{ message.startCountdown }}''</div>
        </div>
        <!-- 引用消息 -->
        <div
          class="quote-message-wrap"
          :class="{
            self: message.fromUser.isGroup
              ? message.fromUser.selfId === $store.getters.userInfo.id
              : message.fromUser.id === $store.getters.userInfo.id ||
                message.fromUser.selfId === $store.getters.userInfo.id,
          }"
          v-if="message.messageQuote"
        >
          <!-- 文字类型 -->
          <div
            class="quote-message quote-text-message"
            v-if="message.messageQuote.subType === 'text'"
            :class="{
              hasMore:
                message.messageQuote.data &&
                message.messageQuote.data.length + quoteName(message).length >
                  44,
            }"
          >
            <!-- url類型 -->
            <div
              v-if="
                message.messageQuote.urlIdentifyContent &&
                  message.messageQuote.urlIdentifyContent.status
              "
              class="quote-urlIdentifyContent"
              @click="gotoUrl(message.messageQuote.urlIdentifyContent.url)"
            >
              <div class="text-wrap">
                {{ quoteName(message) }}：<i
                  class="iconfont_Me icon-a-zu10131"
                  style="font-size:16px;margin-right:2px"
                ></i>
                {{ message.messageQuote.urlIdentifyContent.title }}
              </div>
              <div class="img-wrap">
                <el-image
                  fit="cover"
                  :src="message.messageQuote.urlIdentifyContent.image"
                >
                  <div slot="error" class="error-img">
                    <i class="iconfont_Me icon-link"></i>
                  </div>
                </el-image>
              </div>
            </div>
            <!-- 纯文字类型 -->
            <div v-else>
              <div style="display:flex;padding: 6px 9px;">
                <div class="text-message">
                  {{ quoteName(message) }}：
                  <span
                    v-html="
                      $emoReplaceToImage(
                        hyperlinksCheck(message.messageQuote.data).replace(
                          /\n/g,
                          '<br>'
                        )
                      )
                    "
                  ></span>
                </div>
                <div
                  class="more-text"
                  @click="changeMoreStatus"
                  v-if="
                    message.messageQuote.data &&
                      message.messageQuote.data.length +
                        quoteName(message).length >
                        44
                  "
                >
                  <i class="iconfont_Me icon-up-fill"></i>
                </div>
              </div>
              <!-- 翻译部分 -->
              <div
                v-if="
                  message.messageQuote &&
                    message.messageQuote.translateMessage &&
                    translateMessage(message).translateMsg &&
                    $store.state.imStore.otherPartyInfo
                      .characterConvertStatus === 1
                "
                class="quote_message_text_translate"
                v-html="
                  $emoReplaceToImage(translateMessage(message).translateMsg)
                "
              ></div>
            </div>
          </div>
          <!-- 图片类型 -->
          <div
            class="quote-message"
            v-if="message.messageQuote.subType === 'pic'"
          >
            {{ quoteName(message) }}：
            <el-image
              class="img"
              :zIndex="2022"
              :preview-src-list="[message.messageQuote.url]"
              :src="message.messageQuote.url"
            >
            </el-image>
          </div>
          <!-- 视频类型 -->
          <div
            class="quote-message"
            v-if="message.messageQuote.subType === 'video'"
          >
            {{ quoteName(message) }}：
            <div class="qu-video">
              <el-video
                class="video"
                style="max-width:30px;"
                :src="message.messageQuote.url"
              />
            </div>
          </div>
          <!-- 文件类型 -->
          <div
            class="quote-message"
            v-if="message.messageQuote.subType === 'file'"
          >
            {{ quoteName(message) }}：
            <div
              class="qu-file"
              @click="handleContentClick(message.messageQuote)"
            >
              <i
                class="iconfont_Me icon-a-zu10128"
                style="font-size:18px;color:#aaa;margin-right:1px;margin-top:-2px"
              ></i>
              <div class="file-name">{{ message.messageQuote.fileName }}</div>
              <div class="file-img">
                <el-image
                  style="height: 100%;"
                  :src="
                    formartNewFileIcon(nameSfx(message.messageQuote.fileName))
                  "
                ></el-image>
              </div>
            </div>
          </div>
          <!-- 语音类型 -->
          <div
            class="quote-message quote-voice-message"
            v-if="message.messageQuote.subType === 'multimedia'"
            :class="{
              hasMore:
                message.messageQuote.translateMessage &&
                message.messageQuote.translateMessage.speechConvertMessage &&
                message.messageQuote.translateMessage.speechConvertMessage
                  .length > 44,
            }"
          >
            <div class="quote-voice-name">{{ quoteName(message) }}：</div>
            <div class="qu-vioce">
              <qu-voice :message="message.messageQuote"></qu-voice>
            </div>
            <div>
              <div
                class="qu-voice__speechConvertMessage"
                v-if="
                  message.messageQuote.translateMessage &&
                    message.messageQuote.translateMessage.speechConvertMessage
                "
              >
                <div class="text-wrap">
                  {{
                    message.messageQuote.translateMessage.speechConvertMessage
                  }}
                </div>
                <div
                  class="more-text"
                  @click="changeMoreStatus"
                  v-if="
                    message.messageQuote.translateMessage.speechConvertMessage
                      .length > 44
                  "
                >
                  <i class="iconfont_Me icon-up-fill"></i>
                </div>
              </div>
              <!-- 翻译部分 -->
              <p
                class="qu-voice__speechConvertMessage_translate"
                v-if="
                  message.messageQuote.translateMessage &&
                    $store.state.imStore.otherPartyInfo
                      .characterConvertStatus === 1
                "
                :class="{
                  than50:
                    message.messageQuote.translateMessage
                      .speechConvertMessage &&
                    message.messageQuote.translateMessage.speechConvertMessage
                      .length > 50,
                }"
              >
                {{
                  translateMessage(message) &&
                    translateMessage(message)["translateMsg"]
                }}
              </p>
            </div>
          </div>
          <!-- 分享的群/人类型等 -->
          <div
            class="quote-message"
            v-if="message.messageQuote.serviceType === 'group'"
            @click="go2page('group', message.messageQuote.uuid)"
          >
            <!-- 分享人名稱 -->
            <span class="fs0">{{ quoteName(message) }}</span
            >：
            <!-- 分享群的圖標 -->
            <i
              class="iconfont_Me icon-Frame-2"
              style="font-size:14px;margin-right:2px;margin-top:1px;color:#666"
            ></i>
            <div class="qu-group ellips_2" :title="message.messageQuote.title">
              {{ message.messageQuote.title }}
            </div>
            <!-- 分享群的圖片 -->
            <div class="qu-group-img">
              <el-image :src="message.messageQuote.picurl"></el-image>
            </div>
          </div>
          <!-- 分享的名片 -->
          <div
            class="quote-message"
            v-if="message.messageQuote.serviceType === 'people'"
            @click="go2page('people', message.messageQuote.uuid)"
          >
            <div class="quote-name">{{ quoteName(message) }}</div>
            ：
            <div class="qu-people">
              <i
                class="iconfont_Me icon-Frame-1"
                style="font-size:14px;margin-right:2px;margin-top:2px;color:#666"
              ></i>
              <div class="text-message" :title="message.messageQuote.title">
                {{ message.messageQuote.title }}
              </div>
            </div>
            <div class="qu-people-img">
              <el-image :src="message.messageQuote.picurl"></el-image>
            </div>
          </div>
          <!-- 分享的帖子 -->
          <div
            class="quote-message"
            v-if="message.messageQuote.serviceType === 'service'"
            @click="go2page('service', message.messageQuote.uuid)"
          >
            {{ quoteName(message) }}：
            <i
              class="iconfont_Me icon-Frame1"
              style="font-size:14px;margin-right:2px;margin-top: 2px;color:#666"
            ></i>
            <div style="flex:1">
              <div class="text-message">
                {{
                  message.messageQuote.title.trim() ||
                    message.messageQuote.briefInfo
                }}
              </div>
            </div>
            <div style="width: 40px;" v-if="message.messageQuote.picurl">
              <el-image :src="message.messageQuote.picurl"></el-image>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Bus from "@/utils/bus.js";
import Jquery from "jquery";
import { currentLoginVipStatus } from "@/api/newVersion/vip";
// 图片
import ImelinkMessageImage from "../message/image";
// 文本
import ImelinkMessageText from "../message/text";
// 播放器
import ImelinkMessageVideo from "../message/video";
// 文件
import ImelinkMessageFile from "../message/file";
// 聊天通知
import ImelinkMessageNotice from "../message/notice";
// 语音
import ImelinkMessageVoice from "../message/voice";
// 问题
import ImelinkMessageQs from "../message/qs";
// 加密消息
import ImelinkMessageEncrypted from "../message/encrypted";
// url
import ImelinkMessageWebUrl from "../message/webUrl";
import quVoice from "../message/quVoice";

const components = {
  ImelinkMessageImage,
  ImelinkMessageText,
  ImelinkMessageVideo,
  ImelinkMessageFile,
  ImelinkMessageNotice,
  ImelinkMessageVoice,
  ImelinkMessageQs,
  ImelinkMessageEncrypted,
  ImelinkMessageWebUrl,
  quVoice,
};

import * as openpgp from "openpgp";
import { reapetToNum, env, handlerDecrypt, downloadFile } from "@/utils";
import { securityCheck } from "@/newVersion/components/accountSecurityDialog/api";
import axios from "axios";
import { ArrayBufferToWordArray, AESDecData } from "@/utils/aes";
let cancel;
let task = {};
export default {
  name: "MessageView",
  components,
  inject: ["control"],
  data() {
    return {
      state: {},
      startCountdown: 0,
      downLoadProgress: 0,
      decryptMessageProgress: 0,
      isFistFlag: true,
      audioEnd: false,
      interval: null,
      isFistInterVal: true,
    };
  },
  computed: {
    localLangCode() {
      return localStorage.getItem("langCode");
    },
    translateMessage() {
      return function(msg) {
        return this.msgFormat(msg);
      };
    },
    quoteName() {
      return function(msg) {
        return this.quoteNameFormat(msg);
      };
    },
    IMWindowSize() {
      return this.$store.state.imStore.IMWindowSize;
    },
    alialocale() {
      return this.$store.state.imStore.currentLanguageEnvironment;
    },
    languageEnvironment() {
      let languageEnvironment = null;
      const languages = this.getGlobalLanguages();
      if (languages[this.alialocale]) {
        languageEnvironment = languages[this.alialocale].code;
      }
      this.control.currentContact["code"] = this.alialocale;
      return languageEnvironment;
    },
  },
  mounted() {
    let self = this;
    Bus.$on("updateMessage", () => {
      self.currentMessage();
      self.$forceUpdate();
    });
    Bus.$on("decrypt", function(a, id) {
      self.decrypt(a, id);
    });
    Bus.$on("desMessage", (messege_id) => {
      this.$nextTick(() => {
        document.getElementById(messege_id) &&
          document.getElementById(messege_id).remove();
        this.$forceUpdate();
      });
    });
    Bus.$on("cancelDown", function(a) {
      cancel();
      self.resetStatus();
    });
    Bus.$on("messageVoiceStartTiming", function(msg) {
      self.messageVoiceStartTimingFn(msg);
    });
    // 监听语音是否播放完成
    Bus.$on("messageVoiceAudioEnd", function(msg) {
      task[msg.id].audioEnd = true;
    });
    // 监听视频是否点击了播放
    Bus.$on("messageVideoStartTiming", (id) => {
      self.messageVideoStartTimingFn(id);
    });
    // 监听是否点击了附件
    Bus.$on("messageFileStartTiming", (id) => {
      self.messageVideoStartTimingFn(id);
    });
    if (!window._cancelQuote) {
      window._cancelQuote = () => {
        let innerWrap = document.querySelector(".inner-wrap");
        console.log(innerWrap);
        if (innerWrap) {
          let currentEditorInputCache = this.control.editorInputCache[
            this.control.currentContact.id
          ];
          if (
            currentEditorInputCache &&
            currentEditorInputCache.indexOf("inner-wrap") != -1
          ) {
            let xx = currentEditorInputCache.split("<br>");
            if (xx[1]) {
              this.control.editorInputCache[this.control.currentContact.id] =
                xx[0];
            }
          }
          this.$store.commit("setQuoteMessageIds", {});
          innerWrap.remove();
        }
      };
    }
  },
  beforeDestroy() {
    Bus.$off("decrypt");
    Bus.$off("desMessage");
    Bus.$off("cancelDown");
    Bus.$off("messageVoiceStartTiming");
    Bus.$off("messageVoiceAudioEnd");
    Bus.$off("messageVideoStartTiming");
    Bus.$off("messageFileStartTiming");
    Bus.$off("updateMessage");
    window._cancelQuote = null;
    if (task) {
      for (const key in task) {
        task[key].isFistFlag = true;
        clearInterval(task[key].interval);
      }
    }
  },
  watch: {
    "control.messageViewState": {
      handler(newly, oldly) {
        if (newly == false) {
          this.scrollToBottom();
        }
      },
      deep: true,
    },
    "control.currentContact": {
      handler(newly, oldly) {
        this.state = {
          showLoading: false,
          topLoading: false,
          btmLoading: false,
          topEnd: false,
          btmEnd: false,
        };
      },
      deep: true,
    },
    IMWindowSize: {
      handler(newly, oldly) {
        if (newly != oldly) {
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    currentMessage() {
      return this.control.getCurrentMessage();
    },
    async goodclick(message, ev) {
      if (
        ev.target.className.indexOf("ImelinkMessageEncrypted") != -1 ||
        ev.target.className.indexOf("encryp-block") != -1 ||
        ev.target.className.indexOf("icon-a-lujing13297") != -1 ||
        ev.target.className.indexOf("lock-block") != -1
      ) {
        // 这里先判断自己是不是会员，如果不是会员，则跳转到开通会员页
        const loginVipStatus = await currentLoginVipStatus();
        if (!loginVipStatus.data.data.vipStatus) {
          this.$confirm(this.$t("BecomeemberOpenEncryptedMessages"), "提示", {
            confirmButtonText: " ",
            type: "warning",
            customClass: "melinked-tips",
            showCancelButton: false,
            confirmButtonClass: "el-icon-right my-confirm-btn",
          })
            .then(() => {
              this.$store.commit("setVipModeStatus", true);
            })
            .catch(() => {});
        } else {
          this.$store.state.vip.currentDecryptMessages = Object.assign(
            {},
            this.$store.state.vip.currentDecryptMessages,
            { [message.id]: message }
          );
          this.$store.commit("setDecryptModeStatus", {
            visible: true,
            messageId: message.id,
          });
        }
      }
    },
    async decrypt(loginPassword, messageId) {
      let currentDecryptMessage = this.$store.state.vip.currentDecryptMessages[
        messageId
      ];
      //消息已失效
      if (currentDecryptMessage.encryptMessage === 2) {
        this.$message({
          type: "error",
          message: this.$t("messageHasExpired"),
        });
        return false;
      }
      let self = this;
      let params = {
        codeType: "4",
        password: this.fnencrypt(loginPassword),
        providerId: this.$store.state.accountStore.secureInfo.providerId,
      };
      const result = await securityCheck(params);
      if (result && result.code === 200 && result.data.data) {
        let aesEncryptPrivateKey = null;
        // let publicKeyArmored = null;
        const xx = await currentLoginVipStatus();
        if (xx.code == 200) {
          let keyStr = reapetToNum(loginPassword, 16);
          // 获取用户私钥
          aesEncryptPrivateKey = xx.data.data.privateKey;
          // publicKeyArmored = xx.data.data.publicKey;
          //  不是文本类消息
          if (
            currentDecryptMessage.type != "text" &&
            currentDecryptMessage.type != "webUrl"
          ) {
            try {
              // 通过AES解密获取被加密pgp私钥
              let desEncryptPrivateKey = handlerDecrypt(
                aesEncryptPrivateKey,
                keyStr
              );
              // 这里把IOS生成的PUBLIC转换为PRIVATE
              desEncryptPrivateKey = desEncryptPrivateKey.replace(
                /PUBLIC/g,
                "PRIVATE"
              );
              currentDecryptMessage.encryptKeyMe = currentDecryptMessage.encryptKeyMe.replace(
                /PUBLIC KEY BLOCK/g,
                "MESSAGE"
              );
              currentDecryptMessage.encryptKey = currentDecryptMessage.encryptKey.replace(
                /PUBLIC KEY BLOCK/g,
                "MESSAGE"
              );
              // 使用被加密pgp私钥获取被解密的pgp私钥
              const noPassphrasePrivateKey = await openpgp.readPrivateKey({
                armoredKey: desEncryptPrivateKey,
              });
              let selfId = currentDecryptMessage.fromUser.id;
              if (currentDecryptMessage.fromUser.selfId) {
                selfId = currentDecryptMessage.fromUser.selfId;
              }
              // 是否是自己的消息
              let isSelfMeassge = selfId === this.$store.getters.userInfo.id;
              const message = await openpgp.readMessage({
                armoredMessage: isSelfMeassge
                  ? currentDecryptMessage.encryptKeyMe
                  : currentDecryptMessage.encryptKey, // parse armored message
              });
              let fileNameDecrypted = "";
              if (
                currentDecryptMessage.type == "file" &&
                currentDecryptMessage.encryptFileName &&
                currentDecryptMessage.encryptFileNameMe
              ) {
                currentDecryptMessage.encryptFileNameMe = currentDecryptMessage.encryptFileNameMe.replace(
                  /PUBLIC KEY BLOCK/g,
                  "MESSAGE"
                );
                currentDecryptMessage.encryptFileName = currentDecryptMessage.encryptFileName.replace(
                  /PUBLIC KEY BLOCK/g,
                  "MESSAGE"
                );
                const fileName = await openpgp.readMessage({
                  armoredMessage: isSelfMeassge
                    ? currentDecryptMessage.encryptFileNameMe
                    : currentDecryptMessage.encryptFileName, // parse armored message
                });
                const fileNameData = await openpgp.decrypt({
                  message: fileName,
                  decryptionKeys: noPassphrasePrivateKey,
                });
                fileNameDecrypted = fileNameData.data;
              }
              // 通过pgp私钥进行pgp解密获得AES密码
              const { data: decrypted } = await openpgp.decrypt({
                message,
                decryptionKeys: noPassphrasePrivateKey,
              });
              let file_path =
                currentDecryptMessage.onlineurl || currentDecryptMessage.url;

              self.$store.commit("setDecryptModeStatus", {
                visible: false,
                messageId: null,
              });
              // 下载文件
              const CancelToken = axios.CancelToken;
              axios
                .get(file_path, {
                  responseType: "arraybuffer",
                  headers: {
                    "Content-Type":
                      "application/x-www-form-urlencoded; charset=UTF-8",
                  },
                  onDownloadProgress: function(progressEvent) {
                    //axios封装的原生获取下载进度的事件，该回调参数progressEvent中包含下载文件的总进度以及当前进度
                    if (progressEvent.lengthComputable) {
                      //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                      //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
                      let downLoadProgress =
                        (progressEvent.loaded / progressEvent.total) * 100; //实时获取最新下载进度
                      self.downLoadProgress = downLoadProgress;
                    }
                  },
                  cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                  }),
                })
                .then(function(res) {
                  self.downLoadProgress = 100;
                  self.decryptMessageProgress = 0;
                  let decInterval = setInterval(() => {
                    self.decryptMessageProgress += 10;
                  }, 1000);
                  var response = res.data;
                  /**  尝试进行AES解密文件，再下载，然后尝试打开 */
                  const DecwordBuffer = ArrayBufferToWordArray(response);
                  const DecfileData = AESDecData(
                    DecwordBuffer,
                    decrypted,
                    decrypted
                  );
                  const decfileBlob = new Blob([DecfileData], {
                    type: "application/txt",
                  });
                  var decblobURL = window.URL.createObjectURL(decfileBlob);
                  if (decblobURL) {
                    self.decryptMessageProgress = 100;
                    clearInterval(decInterval);
                  }
                  // 解密成功后的操作
                  const messege_id = currentDecryptMessage.id;
                  const message_parms = {
                    text: decrypted,
                    fileName: fileNameDecrypted,
                    encryptMessage: 0,
                    startCountdown: self.startCountdown,
                    onlineurl: decblobURL,
                    url: decblobURL,
                    isDecrypted: true,
                    isSelfMeassge,
                  };

                  !task[messege_id] &&
                    (task[messege_id] = {
                      isFistFlag: true,
                      type: currentDecryptMessage.type,
                      audioEnd: false,
                    });

                  self.$nextTick(() => {
                    self.control.updateMessage({
                      id: messege_id,
                      ...message_parms,
                    });
                    self.$forceUpdate();
                    if (!isSelfMeassge) {
                      if (currentDecryptMessage.type == "image") {
                        if (!task[messege_id].isFistFlag) {
                          return false;
                        }
                        task[messege_id].isFistFlag = false;
                        self.startTiming(
                          messege_id,
                          (id) => {
                            // 通过服务器删除消息
                            self.control.$emit(
                              "burn-after-reading",
                              self.$store.state.vip.currentDecryptMessages[id],
                              self.control.currentContact.id,
                              isSelfMeassge
                            );
                            delete self.$store.state.vip.currentDecryptMessages[
                              id
                            ];
                            // 本地做物理删除
                            document.getElementById(id) &&
                              document.getElementById(id).remove();
                            self.$forceUpdate();
                          },
                          30
                        );
                      }
                    }
                  });
                });
            } catch (error) {
              console.log(error);
              this.$message({
                type: "error",
                message: this.$t("messageDecryptionFailed"),
              });
            }
          } else {
            // 文本类型
            try {
              let desEncryptPrivateKey;
              try {
                desEncryptPrivateKey = handlerDecrypt(
                  aesEncryptPrivateKey,
                  keyStr
                );
              } catch (error) {
                console.log(error);
              }
              // 这里把IOS生成的PUBLIC转换为PRIVATE
              desEncryptPrivateKey = desEncryptPrivateKey.replace(
                /PUBLIC/g,
                "PRIVATE"
              );
              const noPassphrasePrivateKey = await openpgp.readPrivateKey({
                armoredKey: desEncryptPrivateKey,
              });
              currentDecryptMessage.encryptContentMe = currentDecryptMessage.encryptContentMe.replace(
                /PUBLIC KEY BLOCK/g,
                "MESSAGE"
              );
              currentDecryptMessage.encryptContent = currentDecryptMessage.encryptContent.replace(
                /PUBLIC KEY BLOCK/g,
                "MESSAGE"
              );
              let selfId = currentDecryptMessage.fromUser.id;
              if (currentDecryptMessage.fromUser.selfId) {
                selfId = currentDecryptMessage.fromUser.selfId;
              }
              // // 是否是自己的消息
              let isSelfMeassge = selfId === this.$store.getters.userInfo.id;

              const message = await openpgp.readMessage({
                armoredMessage: isSelfMeassge
                  ? currentDecryptMessage.encryptContentMe
                  : currentDecryptMessage.encryptContent, // parse armored message
              });
              const { data: decrypted } = await openpgp.decrypt({
                message,
                decryptionKeys: noPassphrasePrivateKey,
              });
              // 解密成功后的操作
              const messege_id = currentDecryptMessage.id;
              const message_parms = {
                isDecrypted: true,
                text: decrypted,
                encryptMessage: 0,
                startCountdown: this.startCountdown,
              };

              !task[messege_id] &&
                (task[messege_id] = {
                  isFistFlag: true,
                  type: currentDecryptMessage.type,
                });

              this.$nextTick(() => {
                this.control.updateMessage({
                  id: messege_id,
                  ...message_parms,
                });
                this.$forceUpdate();

                this.$store.commit("setDecryptModeStatus", {
                  visible: false,
                  messageId: null,
                });
                if (!isSelfMeassge) {
                  if (!task[messege_id].isFistFlag) {
                    return false;
                  }
                  task[messege_id].isFistFlag = false;
                  this.audioEnd = true;
                  this.startTiming(
                    messege_id,
                    (id) => {
                      // 通过服务器删除消息
                      this.control.$emit(
                        "burn-after-reading",
                        this.$store.state.vip.currentDecryptMessages[id],
                        this.control.currentContact.id,
                        isSelfMeassge
                      );
                      delete this.$store.state.vip.currentDecryptMessages[id];
                      // 本地做物理删除
                      document.getElementById(id) &&
                        document.getElementById(id).remove();
                      this.$forceUpdate();
                    },
                    30
                  );
                }
                this.$forceUpdate();
              });
            } catch (error) {
              this.$message({
                type: "error",
                message: this.$t("messageDecryptionFailed"),
              });
            }
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    resetStatus() {
      this.downLoadProgress = 0;
    },
    getMessageById(id) {
      const messages = this.currentMessage();
      const filters = messages.filter((message) => message.id == id);
      return filters[0];
    },
    // 开始倒计时
    startTiming(messege_id, fn, initial = 0) {
      // initial 初始值，默认30s
      const message_parms = {
        startCountdown: initial,
      };
      // 先更新一下时间
      this.$nextTick(() => {
        this.control.updateMessage({ id: messege_id, ...message_parms });
        this.$forceUpdate();
      });
      // 语音单独处理，有播放中的状态
      if (
        this.$store.state.vip.currentDecryptMessages[messege_id].type ===
        "voice"
      ) {
        task[messege_id].interval = setInterval(() => {
          let currentCountdown = this.getMessageById(messege_id).startCountdown;
          currentCountdown > 0 && currentCountdown--;
          this.$nextTick(() => {
            const new_parms = {
              startCountdown: currentCountdown,
            };
            this.control.updateMessage({ id: messege_id, ...new_parms });
            if (new_parms.startCountdown == 0 && task[messege_id].audioEnd) {
              clearInterval(task[messege_id].interval);
              fn && fn(messege_id);
            }
            this.$forceUpdate();
          });
        }, 1000);
      } else {
        task[messege_id].interval = setInterval(() => {
          message_parms.startCountdown > 0 && message_parms.startCountdown--;
          this.$nextTick(() => {
            task[messege_id].startCountdown = message_parms.startCountdown;
            this.control.updateMessage({ id: messege_id, ...message_parms });
            if (message_parms.startCountdown == 0) {
              clearInterval(task[messege_id].interval);
              fn && fn(messege_id);
            }
            this.$forceUpdate();
          });
        }, 1000);
      }
    },
    _listenerFn(el) {
      // 如果已经滚动超出一屏的话，则有新消息不会自动滚动到底部
      this.control.scrollHandle(
        el.scrollHeight - (el.scrollTop + el.clientHeight) > el.offsetHeight
      );
    },
    _onTop() {
      console.log("向上滚动");
      this.state.showLoading = true;
      if (this.state.topLoading == true || this.state.topEnd == true) return;
      this.state.topLoading = true;
      if (!this.$refs.view) return;
      const { control } = this;
      const prevScrollHeight =
        this.$refs.view.scrollHeight - this.$refs.view.scrollTop;
      control._emitRequestMessage({
        onSuccess: async (data) => {
          const curmsg = control.getCurrentMessage();
          if (curmsg) {
            const existsIds = curmsg.map((item) => item.id);
            data = data.filter((item) => {
              if (!existsIds.includes(item.id)) return item;
            });
          }
          control.prependMessage([...data]);
          if (!data || data.length == 0) {
            this.state.topLoading = true;
            this.state.topEnd = true;
          } else {
            this.state.topLoading = false;
          }

          await this.$nextTick();
          if (!this.$refs.view) return;
          this.scrollTo(this.$refs.view.scrollHeight - prevScrollHeight);
        },
        onError() {
          this.state.topLoading = false;
        },
        source: "scrollToTop",
      });
    },
    _onBottom() {
      console.log("向下滚动");
      const { control } = this;
      if (this.state.btmLoading == true || this.state.btmEnd == true) return;
      this.state.btmLoading = true;
      control._emitRequestMessage({
        onSuccess: (data) => {
          const curmsg = control.getCurrentMessage();
          if (curmsg) {
            const existsIds = curmsg.map((item) => item.id);
            data = data.filter((item) => {
              if (!existsIds.includes(item.id)) return item;
            });
          }
          control.staticAppendMessage(data);
          if (!data || data.length < 10) {
            this.state.btmLoading = true;
            this.state.btmEnd = true;
          } else {
            this.state.btmLoading = false;
          }
        },
        onError() {
          this.state.topLoading = false;
        },
        source: "scrollToBottom",
      });
    },
    scrollTo(val = "0px") {
      this.$refs.view.scrollTop = val;
    },
    async scrollToBottom() {
      await this.$nextTick();
      this.scrollTo(this.$refs.view.scrollHeight);
    },
    handlerChangeMsgT(data) {
      this.control._changeTranslateMsg(data);
    },
    messageVoiceStartTimingFn(msg) {
      let self = this;
      !task[msg.id] &&
        (task[msg.id] = {
          isFistFlag: true,
          audioEnd: false,
        });
      task[msg.id].audioEnd = false;
      let currentDecryptMessage =
        self.$store.state.vip.currentDecryptMessages[msg.id];
      if (!task[msg.id].isFistFlag) {
        return false;
      }
      let selfId = currentDecryptMessage.fromUser.id;
      if (currentDecryptMessage.fromUser.selfId) {
        selfId = currentDecryptMessage.fromUser.selfId;
      }
      let isSelfMeassge = selfId === self.$store.getters.userInfo.id;
      let messege_id = currentDecryptMessage.id;
      if (!isSelfMeassge) {
        task[msg.id].isFistFlag = false;
        self.startTiming(
          messege_id,
          (id) => {
            // 通过服务器删除消息
            self.control.$emit(
              "burn-after-reading",
              self.$store.state.vip.currentDecryptMessages[id],
              self.control.currentContact.id,
              isSelfMeassge
            );
            delete this.$store.state.vip.currentDecryptMessages[id];
            // 本地做物理删除
            setTimeout(() => {
              document.getElementById(id) &&
                document.getElementById(id).remove();
              self.$forceUpdate();
            }, 100);
          },
          10 + msg.duration
        );
      }
    },
    messageVideoStartTimingFn(messageId) {
      let currentDecryptMessage = this.$store.state.vip.currentDecryptMessages[
        messageId
      ];
      let messege_id = currentDecryptMessage.id;
      !task[messege_id] &&
        (task[messege_id] = {
          isFistFlag: true,
        });
      if (!task[messege_id].isFistFlag) {
        return false;
      }
      let selfId = currentDecryptMessage.fromUser.id;
      if (currentDecryptMessage.fromUser.selfId) {
        selfId = currentDecryptMessage.fromUser.selfId;
      }
      // 是否是自己的消息
      let isSelfMeassge = selfId === this.$store.getters.userInfo.id;
      if (!isSelfMeassge) {
        task[messege_id].isFistFlag = false;
        this.startTiming(
          messege_id,
          (id) => {
            // 通过服务器删除消息
            this.control.$emit(
              "burn-after-reading",
              this.$store.state.vip.currentDecryptMessages[id],
              this.control.currentContact.id,
              isSelfMeassge
            );
            delete this.$store.state.vip.currentDecryptMessages[id];
            // 本地做物理删除
            document.getElementById(id) && document.getElementById(id).remove();
            this.$forceUpdate();
          },
          60
        );
      }
    },
    playVideo() {
      document.querySelector(".qu-video video").play();
    },
    nameSfx(name) {
      if (!name) return "";
      const arr = name.split(".");
      let str = arr[arr.length - 1].toUpperCase();
      if (str.length > 3) {
        str = str.substring(0, 3);
      }
      return str;
    },
    // 下载当前文件
    handleContentClick(message) {
      let url = null;
      let isBblob = message.url.indexOf("blob") != "-1";
      if (isBblob) {
        url = message.onlineurl || message.url;
      } else {
        url = `${env(
          "BASEURL"
        )}/rest/user/download?downloadUrl=${this.formartImageUrl(
          message.onlineurl || message.url
        )}&fileName=${encodeURIComponent(
          message.fileName
            .replace('<font color="red">', "")
            .replace("</font>", "")
        )}`;
      }
      downloadFile(
        url,
        message.fileName
          .replace('<font color="red">', "")
          .replace("</font>", "")
      );
    },
    changeMoreStatus(e) {
      let parent,
        classList = null;
      if (
        e.target.classList[1] === "icon-up-fill" ||
        e.target.classList[1] === "icon-down-fill"
      ) {
        parent = Jquery(e.target).parents(".hasMore");
        classList = parent[0].classList;
        let quote_message_text_translateNo = parent
          .children()
          .children(".quote_message_text_translate");
        if (!classList.contains("all") && classList.contains("hasMore")) {
          parent.addClass("all");
          Jquery(".than50").css("display", "block");
          Jquery(quote_message_text_translateNo[0]).css("display", "block");
          Jquery(e.target)[0].className = "iconfont_Me icon-down-fill";
        } else {
          Jquery(quote_message_text_translateNo[0]).css("display", "none");
          Jquery(".than50").css("display", "none");
          Jquery(e.target)[0].className = "iconfont_Me icon-up-fill";
          parent.removeClass("all");
        }
      }
    },
    go2page(type, id) {
      if (type === "people") {
        this.routeJump({
          name: "personalCenter",
          params: { id: id },
        });
      }
      if (type === "group") {
        this.routeJump({
          name: "groupCenter",
          params: { id: id },
        });
      }
      if (type === "service") {
        this.routeJump({
          name: "postContentDetails",
          params: { id: id },
        });
      }
    },
    gotoUrl(url) {
      window.open(url);
    },
    msgFormat(msg) {
      let translateMessage = msg.messageQuote.translateMessage;
      if (translateMessage && typeof translateMessage === "string") {
        translateMessage = JSON.parse(translateMessage);
      }
      let msgs = translateMessage.msgs;
      let translist = [];
      let selfTransmsg = {};
      if (msgs) {
        msgs.map((item) => {
          if (
            item.language !==
              msg.messageQuote.translateMessage.sourceLanguage &&
            item.translateMsg &&
            this.$emoReplaceToImage(item.translateMsg) &&
            this.control.currentContact.code &&
            item.language == this.languageEnvironment
          )
            selfTransmsg = { ...item, self: true };
          else translist.push(item);
        });
        // 只显示一种语言
        let localLangCode = localStorage.getItem("langCode");
        translist = translist.filter((item) => {
          return item.language == localLangCode;
        });
        if (Object.keys(selfTransmsg).length) translist.unshift(selfTransmsg);
        return selfTransmsg;
      }
      return translist;
    },
    quoteNameFormat(msg) {
      if (msg) {
        let name = msg.messageQuote.name || msg.messageQuote.actor;
        if (name) {
          return this.slice10(name);
        } else {
          if (msg.messageQuote.from === msg.fromUser.id) {
            return this.slice10(msg.fromUser.displayName);
          } else {
            return this.slice10(this.control.currentContact.displayName);
          }
        }
      }
    },
    slice10(name) {
      return name.length > 10 ? name.slice(0, 10) + ".." : name;
    },
  },
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

fromuser-skew = 58px;
status-skew = 22px;

+b(imelink-message-view) {
  background: imelink-message-view-background;
  padding: 20px 15px;
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-light();
  position: relative;
}

+b(imelink-message) {
  display: block;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;

  +m(origin) {
    margin-bottom: 30px;
  }

  +e(origin) {
    position: absolute;
    bottom: -20px;
    right: auto;
    left: 50px;
    font-size: 12px;
    color: #52bd68;
    cursor: pointer;
    user-select: none;
  }

  +e(fromuser) {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
    color: #999;
    position: absolute;
    top: 0;
    left: fromuser-skew;
    display: flex;
  }
  +e(contentInnerBox){
    display flex;
    flex-flow row;
    width: 100%;
    justify-content flex-start;
  }
  +e(contentBox) {
    display flex;
    flex-flow column;
    align-items flex-end;
    width: 100%;
    justify-content center;
  }
  +e(fromname) {
    color: #333;
    padding-right: 10px;
  }

  +e(content) {
    margin: 24px 15px 0 15px;
    max-width: 80%;
    cursor: pointer;
    font-size: 14px;
    border-radius: imelink-bubble-radius;
    position: relative;

    +m(bubble) {
      background: imelink-bubble-background;
      color: imelink-bubble-color;
      padding: 10px 15px;
      line-height: 24px;

      &:after {
        content: '';
        position: absolute;
        left: -13px;
        top: 4px;
        width: 0;
        height: 0;
        font-size: 0;
        border: solid 8px;
        border-color: transparent;
        border-right-color: imelink-bubble-background;
      }
    }
  }

  +e(status) {
    top: status-skew;
    position: relative;
    height: 24px;
    width: 24px;
    line-height: 24px;
    text-align: center;
    color: #f72b03;
    font-size: 22px;

    +m(loading) {
      color: #aaa;
    }

    +m(warning) {
      cursor: pointer;
      hover-tooltip();
    }
  }

  +m(self) {
    flex-direction: row-reverse;

    +b(imelink-message) {
      +e(origin) {
        left: auto;
        right: 60px;
      }

      +e(fromuser) {
        left: auto;
        right: fromuser-skew;
      }
      +e(contentInnerBox){
        display flex;
        flex-flow row;
        justify-content flex-end;
      }
      +e(contentBox) {
        display flex;
        flex-flow column;
        align-items flex-end;
        justify-content center;
      }
      +e(allowChatTips) {
        height 30px;
        display flex;
        flex-flow row;
        padding 0 10px;
        color #333333;
        font-size 14px;
        margin-top 10px;
        font-weight bold;
        margin-right 15px;
        border-radius 4px;
        background #CCCCCC;
        align-items center;
        justify-content center;
      }

      +e(status) {
        .el-loading-spinner {
          right: 18px;
        }
      }

      +e(content) {
        background: imelink-bubble-self-background;
        color: imelink-bubble-self-color;

        &:after {
          border-right-color: transparent;
          border-left-color: imelink-bubble-self-background;
          left: auto;
          right: -13px;
        }
      }
    }
  }
}
</style>
<style>
.countDown {
  width: 36px;
  height: 20px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  margin-left: 56px;
  margin-top: -10px;
}

.file .countDown {
  margin-top: -20px;
}

.file.self.countDown {
  margin-top: -10px;
}

.countDown-wrap {
  position: relative;
  display: flex;
  margin-bottom: 20px;
}

.countDown-wrap.self {
  flex-direction: row-reverse;
}

.countDown-wrap.self .countDown {
  margin-right: 56px;
  margin-bottom: 20px;
}

.isEncryptMode {
  padding-bottom: 50px;
}

.quote-message-wrap {
  position: relative;
  display: flex;
  margin-top: -16px;
  margin-bottom: 20px;
  padding-left: 52px;
}

.quote-message-wrap.self {
  flex-direction: row-reverse;
  padding-right: 52px;
}

.quote-message {
  background: #ebebeb;
  padding: 6px 9px;
  font-size: 12px;
  border-radius: 3px;
  display: flex;
  position: relative;
  max-width: 80%;
}

.quote-message.quote-text-message {
  max-width: 80%;
  padding: 0;
}

.text-message {
  word-break: break-all;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.5;
  /* 这里是超出几行省略 */
  overflow: hidden;
  color: #4d4d4d;
  color: #666;
  margin-right: 3px;
}

.quote-message.all .text-message {
  -webkit-line-clamp: inherit !important;
}

.more-text {
  cursor: pointer;
  font-size: 20px;
  color: #999;
  transform: rotate(180deg);
  display: flex;
  align-self: flex-start;
}

.more-text i {
  font-size: 20px;
}

.quote-message .img {
  max-width: 50px;
  max-height: 108px;
  border-radius: 2px;
}

.quote-message .qu-video {
  position: relative;
}

.quote-message .me-video {
  /* max-width: 30px !important; */
  min-width: 60px !important;
  min-height: 60px !important;
}

.quote-message .me-video__play {
  color: rgba(0, 0, 0, 0.7);
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.quote-message .el-icon-caret-right {
  font-size: 16px;
  transform: translate(-50%, -50%) scale(0.8);
}

.quote-message .qu-file {
  display: flex;
  cursor: pointer;
}

.quote-message .qu-file .file-name {
  max-width: 120px;
  word-break: break-all;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 这里是超出几行省略 */
  overflow: hidden;
  color: #4d4d4d;
  margin-right: 3px;
}

.quote-voice-message {
  max-width: 80%;
}

.quote-message .qu-file .file-img {
  height: 30px;
}

.quote-message .qu-file .file-img .img {
  width: 100%;
  height: 100%;
}

.quote-message .qu-people {
  cursor: pointer;
  display: flex;
  align-items: top;
}

.quote-message .qu-people-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 3px;
  flex-shrink: 0;
}

.quote-message .qu-group-img {
  width: 34px;
  height: 34px;
  margin-left: 3px;
  flex-shrink: 0;
}

.fs0 {
  flex-shrink: 0;
}

.quote-message .qu-people-img img {
  width: 100%;
}

.quote-urlIdentifyContent {
  cursor: pointer;
  display: flex;
  padding: 6px 9px;
}

.quote-urlIdentifyContent .text-wrap {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 这里是超出几行省略 */
  overflow: hidden;
}

.quote-urlIdentifyContent .img-wrap {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-left: 2px;
  background: #fff;
}

.quote-urlIdentifyContent .error-img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-urlIdentifyContent .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quote_message_text_translate {
  margin-top: 2px;
  border-top: 1px solid #fff;
  padding: 6px 9px;
}

.hasMore .quote_message_text_translate {
  display: none;
}

.quote-voice-name {
  flex-shrink: 0;
}

.qu-voice__speechConvertMessage {
  margin-left: 10px;
  display: flex;
}

.qu-voice__speechConvertMessage .text-wrap {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 这里是超出几行省略 */
  overflow: hidden;
}

.ellips_2 {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 这里是超出几行省略 */
  overflow: hidden;
}

.quote-message.all .qu-voice__speechConvertMessage .text-wrap {
  -webkit-line-clamp: inherit !important;
}

.qu-voice__speechConvertMessage_translate {
  margin: 3px 0 0 10px;
  border-top: 1px solid #fff;
  padding-top: 2px;
}

.quote-name {
  flex-shrink: 0;
}

.than50 {
  display: none;
}
</style>
